import { Component, OnInit, OnDestroy } from '@angular/core';
import { DetailButtonBarComponent } from 'src/app/client-core/pages/repository-page/detail-button-bar/detail-button-bar.component';
import { WEvent } from 'src/app/client-core/data/event.model';
import { WResource } from 'src/app/client-core/data/resource.model';
import { Globals } from 'src/app/client-core/services/global.service';
import * as braintreeDropIn from 'braintree-web-drop-in';
import { WField } from 'src/app/client-core/data/field.model';

@Component({
  selector: 'wackadoo-new-customer-detail-button-bar',
  templateUrl: './new-customer-detail-button-bar.component.html',
})
export class NewCustomerDetailButtonBarComponent extends DetailButtonBarComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'NewCustomerDetailButtonBarComponent';

  appPrice: WResource = null;
  app: WResource = null;

  theBigOptIn = '(' + Globals.theBigOptIn + ')';

  saveButtonTitle: string = null;

  clientToken: string = null;
  braintreeInstance = null;

  applicationToPurchase = '';

  ngOnInit(): void {
    super.ngOnInit();

    this.applicationToPurchase = this.userInterfaceService.previousApplication;

    this.saveButtonTitle = this.user.isPublicOnly ? 'Submit' : 'Save';

    // generate the client token needed to access the credit card service...

    this.eventServerService.fireEvent('Billing', 'generateClientToken', {}).subscribe(
      (event: WEvent) => {
        if (event.status !== 'OK') {
          throw event.message;
        }

        this.clientToken = event.getParameter('clientToken');

        this.initializeBrainTreeDropIn();
      }
    );

    // now get the previousApplication resource

    const parms: any = {};
    parms.appName = this.applicationToPurchase;

    // console.log('ngOnInit()', parms);

    this.eventServerService.fireEvent('Applications', 'list', parms).subscribe(
      (result: WEvent) => {
        console.log('result:', result);
        if (result.status !== 'OK') {
          this.modalDialogService.showAlert('Failed to find root application: ' + parms.appName, 'Error!');
        } else {
          this.app = result.resources[0];
          // console.log('this.app:', this.app);

          const parms2: any = this.app.appID.asParm;
          this.eventServerService.fireEvent('AppPricing', 'list', parms2).subscribe(
            (result2: WEvent) => {
              console.log('result2:', result2);
              if (result2.status !== 'OK') {
                this.modalDialogService.showAlert('Failed to find pricing for root application: ' + parms.appName, 'Error!');
              } else {
                this.appPrice = result2.resources[0];
                console.log('this.appPrice:', this.appPrice);
              }
            }
          );
        }
      }
    );

  }

  ngOnDestroy(): void {
    this.clientToken = null;
    this.braintreeInstance = null;
  }

  ///////////////////////////////////
  // button functions...
  ///////////////////////////////////

  save(): void {
    // console.log('save()', this.braintreeInstance);

    if (!this.braintreeInstance) {

      this.modalDialogService.showAlert('Unable to save, due to lack of connection w/credit card processor.', 'Warning');

    } else {

      // this is a highly modified version of regular Detail button bar save(), and wraps the payment stuff inside a CAPTCHA.verify() event...

      // console.log(this.resource, this.resource.asParms, this.resource.getChangedFieldValuesAsParms(false));

      const addMode = !this.resource.keyField.isPopulated;
      let badField = this.resource.identifyMissingOrInvalidField(addMode);

      // If we don't have a key field value, we're doing an "add",
      // in which case, public users MUST have a captchaAnswer value...
      if (addMode && this.user.isPublicOnly) {
        badField = this._checkNextMissingField('captchaAnswer', badField);
      }

      if (badField) {
        this.modalDialogService.showAlert('Invalid field value or missing required field: ' + badField.name, 'Error').subscribe(
          () => {
            this.userInterfaceService.focusOnField(this.repositoryPageElementRef, badField);
          }
        );
      } else if (!(addMode && this.user.isPublicOnly)) {

        this.modalDialogService.showAlert('Only public users may create a new customer!', 'Un-Supported Action!');

      } else {

        // if we get here, we have a validated all required input fields, EXCEPT for the CAPTCHA...

        this.eventServerService.fireEvent('NewCustomer', 'verify', this.resource.asParms).subscribe(
          (event: WEvent) => {
            if (event.status !== 'OK') {

              this.modalDialogService.showAlert(event.message, 'Error').subscribe(
                () => {
                  this.userInterfaceService.focusOnField(this.repositoryPageElementRef, this.resource.captchaAnswer);
                }
              );
            } else {

              // if we get here, we have a validated all required input fields, including the CAPTCHA, and we may proceed...

              const confirmationMessage = 'By clicking the OK button, you are creating an account and authorizing <b>' + Globals.organizationName + '</b> to charge your credit card <b>$'
              + this.appPrice.price.value + ' ' + (this.appPrice.priceModel.value === 'per month' ? this.appPrice.priceModel.value : '') + '</b> to use <b>' + this.applicationToPurchase
              + '.</b><br/><br/>' + this.theBigOptIn;

              this.modalDialogService.showConfirm(confirmationMessage, 'Important!').subscribe(
                (flag: boolean) => {
                  if (flag) {
                    this.modalDialogService.showPleaseWait('Submitting requestPaymentMethod');
                    this.braintreeInstance.requestPaymentMethod(
                      (requestPaymentMethodErr: any, payload: any) => {
                        this.modalDialogService.showPleaseWait(false);
                        // console.log('save()', requestPaymentMethodErr, payload);
                        if (requestPaymentMethodErr) {
                          this.onError(requestPaymentMethodErr, payload);
                        } else {
                          this.onSuccess(payload);
                        }
                      }
                    );

                  }
                }
              );

            }
          }
        );
      }

    }

  }

  cancel(): void {
    this.userInterfaceService.unSelectResource(this.eventHandler, true);
    if (this.user.isPublicOnly) {
      if (Globals.rootApplication === Globals.thisApplication) {
        this.userInterfaceService.loadPage('home');
      } else {
        window.location.href = '/';
      }
    } else {
      super.cancel();
    }
  }

  ///////////////////////////////////
  // Braintree logic, etc...
  ///////////////////////////////////

  initializeBrainTreeDropIn(): void {
    try {
      // console.log('NewCustomerDetailButtonBarComponent.initializeBrainTreeDropIn()');

      /////////////////////////////////////////////////////
      // set up the credit card input section
      /////////////////////////////////////////////////////

      // load the BrainTree drop-in form

      // console.log('NewCustomerDetailButtonBarComponent.initializeBrainTreeDropIn()', braintreeDropIn);

      braintreeDropIn.create(
        {
          authorization: this.clientToken,
          container: '#payment-form',
          card: {
            cardholderName: {
              required: true
            }
          }
        },
        (createErr: any, instance: any) => {
          // console.log('onReady()', createErr, instance);
          this.braintreeInstance = instance;
        }
      );

    } catch (ex) {
      const msg = 'NewCustomerDetailButtonBarComponent.initializeBrainTreeDropIn()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  private _checkNextMissingField(fieldName: string, previousMissingField?: WField): WField {
    previousMissingField = typeof previousMissingField !== 'undefined' ? previousMissingField : null;
    let f: WField = previousMissingField;
    if (!f) {
      const f2: WField = this.resource.getField(fieldName);
      // console.log('InBox._missingField()', fieldName, f2.isPopulated);
      if (f2 && !f2.isPopulated) {
        f = f2;
      }
    }
    return f;
  }

  onSuccess(response: any): void {
    try {

      this.modalDialogService.showPleaseWait(true);

      const parms: any = this.resource.getChangedFieldValuesAsParms();

      /////////////////////////////////////////////////
      // add 'buy-the-app' parameters to the resource...
      /////////////////////////////////////////////////

      parms.payment_method_nonce = response.nonce; // 'fake-valid-visa-nonce';
      parms.appName = this.applicationToPurchase;

      /////////////////////////////////////////////////
      // add email parameters to the resource...
      /////////////////////////////////////////////////

      parms.to = parms.accountEmail;
      parms.fromName = Globals.domain + ' - Account Registration';
      parms.eventUrlPrefix = this.userInterfaceService.getApplicationUrl();
      parms.domain = Globals.domain;

      console.log('About to registerNewCustomer with parms', parms);

      this.eventServerService.fireEvent('NewCustomer', 'registerNewCustomer', parms).subscribe(
        (result: WEvent) => {

          this.modalDialogService.showPleaseWait(false);

          // console.log('Tried to registerNewCustomer with result', result);

          if (result.status !== 'OK') {  // FAILURE!! Something got messed up, and now we have to display a sensible error message...
            // console.log('Failed to registerNewCustomer!', result.message, response, result);

            this.modalDialogService.showAlert('There was a problem registering the new customer information: ' + result.message, 'Unfortunately...');

          } else {  // SUCCESS!! Everything went just fine...

            this.modalDialogService.showAlert('Your account has been successfully created, and your credit card has been verified.\nYou may now login using the credentials you provided.', 'Welcome!').subscribe(
              () => {
                this.cancel();
              }
            );
          }
        }
      );

    } catch (ex) {
      const msg = 'onSuccess()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  onError(errMsg: any, payload: any): void {
    try {
      console.log('onError(' + errMsg + ') - ' + JSON.stringify(payload), payload);

    } catch (ex) {
      const msg = 'onError()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

}
