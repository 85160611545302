import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';
import { Globals } from 'src/app/client-core/services/global.service';

@Component({
  selector: 'wackadoo-org-home-org',
  templateUrl: './home-org.component.html',
})
export class HomeOrgComponent extends PageComponent implements OnInit, OnDestroy, AfterViewInit {

  domain: string;
  appName: string;
  orgName: string;

  images: string [];

  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  isLoggedIn = false;
  userAuthChangedSubscription: Subscription;

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    private _carouselConfig: NgbCarouselConfig,
    private _elementRef: ElementRef,
  ) {
    super(userAuthService, userInterfaceService);

    this._carouselConfig.showNavigationArrows = false;
    this._carouselConfig.showNavigationIndicators = false;
    this._carouselConfig.pauseOnHover = false;
    this._carouselConfig.keyboard = false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.domain = Globals.domain;
    this.orgName = Globals.organizationName;
    this.appName = Globals.thisApplication;

    this.isLoggedIn = this.userAuthService.isLoggedIn;

    this.images = [1, 2, 3, 4].map((i) => './assets/pics/' + this.domain + '.carousel.' + i + '.jpg');

    this.userAuthChangedSubscription = this.userAuthService.currentUser.subscribe(
      () => {
        this.isLoggedIn = this.userAuthService.isLoggedIn;
      }
    );

  }

  ngOnDestroy(): void {
    if (this.userAuthChangedSubscription) {
      this.userAuthChangedSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {

    for (let idx = 0; idx < this.images.length; idx++) {

      const imageUrl = './assets/pics/' + this.domain + '.carousel.' + (idx + 1) + '.jpg';

      // console.log(imageUrl);

      let backgroundImage = 'linear-gradient(';
      backgroundImage += 'to right, ';
      backgroundImage += 'rgba(255, 255, 255, 0) 0%, ';
      backgroundImage += 'rgba(255, 255, 255, 0) 45%, ';
      backgroundImage += 'rgba(255, 255, 255, 0.75) 60%, ';
      backgroundImage += 'rgba(255, 255, 255, 1) 100%), ';
      backgroundImage += 'url("' + imageUrl + '")';

      const fadedImage = this._elementRef.nativeElement.querySelector('.faded-image.image' + idx);

      // we check this because sometimes we don't HAVE images on the public page, like
      // when we immediately jump to the "firstPage" due to business rule processing...

      if (fadedImage) {
        fadedImage.style.backgroundImage = backgroundImage;
        fadedImage.style.backgroundRepeat = 'no-repeat';
        fadedImage.style.backgroundPosition = 'center';
        fadedImage.style.backgroundSize = 'cover';
      }
    }
  }

  loadPage(pageName: string): void {
    this.userInterfaceService.loadPage(pageName);
  }

  isNotExpired(expiration: any): boolean {
    // console.log('expiration:', typeof expiration, expiration);
    return (!expiration) || ((new Date(expiration)).getTime() > (new Date()).getTime());
  }

  get accountManagerIsRootApplication(): boolean {
    return(Globals.rootApplication === 'AccountManager');
  }

}

