<div *ngIf="showFooter">
    <div class="fixed-bottom" wackadooMenuCloser>
        <div class="wackadoo-footer wackadoo-line-top bg-light" *ngIf="isRootApp && !isLoggedIn && !hidePublicFooterContent">
            <div class="container">
                <div class="d-flex justify-content-around small">
                    <div>
                        <div>
                            <a [routerLink]="['home']">Home</a>
                        </div>
                        <div>
                            <a (click)="goToPricing()" >Pricing</a>
                        </div>
                        <div>
                            <a [routerLink]="['contact-us']">Contact Us</a>
                        </div>
                    </div>
                    <div>
                        ABOUT...
                        <div>
                            <a [routerLink]="['about-name']">Our name...</a>
                        </div>
                        <div>
                            <a [routerLink]="['about-mission']">Our mission...</a>
                        </div>
                        <div>
                            <a [routerLink]="['about-technology']">Our technology...</a>
                        </div>
                        <div>
                            <a [routerLink]="['about-history']">Our history...</a>
                        </div>
                        <div>
                            <a [routerLink]="['about-custom-dev']">Custom development...</a>
                        </div>
                    </div>
                    <div>
                        OUR VALUES...
                        <div>
                            <a [routerLink]="['value-directness']">Directness</a>
                        </div>
                        <div>
                            <a [routerLink]="['value-simplicity']">Simplicity</a>
                        </div>
                        <div>
                            <a [routerLink]="['value-flexibility']">Flexibility</a>
                        </div>
                        <div>
                            <a [routerLink]="['value-substance-over-style']">Substance Over Style</a>
                        </div>
                        <div>
                            <a [routerLink]="['value-transparency']">Transparency</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wackadoo-footer wackadoo-line-top bg-light">
            <div class="center">
                <span class="footerContent"><a href="{{ orgNameURL }}">{{ orgName }}</a> &copy; 2015-{{ copyrightYear }}</span>
            </div>
        </div>
    </div>
</div>
