<div class="card" *ngIf="user.isMemberOnly">
  <div class="card-header lightred-bg">
    <a (click)="toggle()" [attr.aria-expanded]="!isCollapsed">You have special Account and User management privileges! Click here to learn more than you ever wanted to know...</a>
  </div>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <div class="card-body lightred-bg well">
      <div>
        These are things you can do in AccountManager for {{ user.accountName }} because you are an AccountManager "member":
        <ul>
          <li>Modify Account details on the Accounts page</li>
          <li>Save credit card information, and purchase or cancel web apps on the Billing page</li>
          <li>Create and modify users on the Users page, re-set passwords, etc.</li>
          <li>Assign users to roles within your registered applications on the Roles page</li>
        </ul>
      </div>
      <div>
        A few notes about user application roles:
        <ul>
          <li>Different roles have different permission levels within each application.</li>
          <li>Users can - and should - be assigned different roles for different applications.</li>
          <li>The help pages in each application will provide information about the capability of each role for that application.</li>
        </ul>
        In this AccountManager app:
        <ul>
          <li>Every user who can login is "authorized" to view their Account, RegisteredApplications, and Roles, but they can only edit their own User details.</li>
          <li>Both the "member" and "guest" roles have full read/write access to any User's information assigned in your account</li>
          <li>Only the "member" role has access to the Billing page</li>
        </ul>
      </div>
      <div>
        Naturally, you will want to be *very*  careful about who you assign the role of "member" in the AccountManager app.
      </div>
      <div>
        We strongly recommend that you NOT login as your AccountManager "member" user for regular daily work.  
        Even if you are the only person at {{ user.accountName }} using this web site, we recommend 
        that you create a separate user, and assign that user the role of "guest" in the 
        AccountManager application. Then, you should use the "guest" user for regular daily work.  
        (In case you have assigned your favorite user name to the "member" role, please note that you are 
        able to change user names.)
      </div>
    </div>
  </div>
</div>
<div class="card" *ngIf="user.isGuestOnly">
  <div class="card-header lightblue-bg">
    <a (click)="toggle()" [attr.aria-expanded]="!isCollapsed">You have special User management privileges! Click here to learn more than you ever wanted to know...</a>
  </div>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <div class="card-body lightblue-bg well">
      <div>
        These are things you can do in AccountManager for {{ user.accountName }} because you are an AccountManager "guest":
        <ul>
          <li>Create and modify users on the Users page, re-set passwords, etc.</li>
          <li>Assign users to roles within your registered applications on the Roles</li>
        </ul>
      </div>
      <div>
        A few notes about user application roles:
        <ul>
          <li>Different roles have different permission levels within each application.</li>
          <li>Users can - and should - be assigned different roles for different applications.</li>
          <li>The help pages in each application will provide information about the capability of each role for that application.</li>
        </ul>
        In this AccountManager app:
        <ul>
          <li>Every user who can login is "authorized" to view their Account, RegisteredApplications, and Roles, but they can only edit their own User details.</li>
          <li>You, having the "guest" role, have full read/write access to any User's information assigned in your account</li>
        </ul>
      </div>
    </div>
  </div>
</div>
