import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewChecked, Input } from '@angular/core';
import { UserAuthService } from '../../services/user-auth.service';
import { UserInterfaceService } from '../../services/user-interface.service';
import { Subscription } from 'rxjs';
import { User } from '../../data/user.model';
import { ScreenType } from '../../services/screen-type.enum';
import { Globals } from '../../services/global.service';

@Component({
  selector: 'wackadoo-banner',
  templateUrl: './banner.component.html',

})
export class BannerComponent implements OnInit, OnDestroy, AfterViewChecked {

  @Input() public showBanner = true;
  @Input() public showLoginMenu = true;

  domain = Globals.domain;
  domainLogo = Globals.domainLogoURL;
  appName = Globals.thisApplication;

  isLoggedIn = false;
  userAuthChangeSubscription: Subscription;

  collapsed = true;
  bannerMenuVisibleSubscription: Subscription;

  screenWidth: number;

  screenType: string;
  screenTypeSubscription: Subscription;

  pageName: string;
  pageNameSubscription: Subscription;

  userName: string;
  userRole: string;
  accountName: string;
  accountLogoURL: string;

  constructor(
    private _userAuthService: UserAuthService,
    private _userInterfaceService: UserInterfaceService,
    private _changeDetector: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {

    // console.log('banner init', this.appName, this.previousApp);

    this.isLoggedIn = this._userAuthService.isLoggedIn;

    this.userAuthChangeSubscription = this._userAuthService.currentUser.subscribe(
      (user: User) => {
          if (user) {
            this.userName = user.fullName;
            this.userRole = user.role;
            this.accountName = user.accountName;

            this.accountLogoURL = this._userInterfaceService.accountLogoURL;

            this.isLoggedIn = this._userAuthService.isLoggedIn;
            // console.log('banner user: ' + user.fullName + ' - ' + user.accountName + ' - is logged in: ' + this.isLoggedIn, user);
        } else {
          // console.log('banner user: ' + user);
        }
      }
    );

    this.screenTypeSubscription = this._userInterfaceService.screenType.subscribe(
      (screenType: ScreenType) => {
        // console.log('banner: ' + JSON.stringify(screenType));
        this.screenType = screenType;
        this.screenWidth = window.outerWidth;
      }
    );

    this.pageNameSubscription = this._userInterfaceService.currentPage.subscribe(
      (pageName: string) => {
        this.pageName = pageName;
      }
    );

    this.bannerMenuVisibleSubscription = this._userInterfaceService.bannerMenuCollapsed.subscribe(
      (collapsed: boolean) => {
        // console.log('banner: ' + JSON.stringify(collapsed));
        this.collapsed = collapsed;
      }
    );

  }

  ngOnDestroy(): void {
    if (this.userAuthChangeSubscription) {
      this.userAuthChangeSubscription.unsubscribe();
    }
    if (this.screenTypeSubscription) {
      this.screenTypeSubscription.unsubscribe();
    }
    if (this.bannerMenuVisibleSubscription) {
      this.bannerMenuVisibleSubscription.unsubscribe();
    }
    if (this.pageNameSubscription) {
      this.pageNameSubscription.unsubscribe();
    }
  }

  // This method is ONLY here to avoid this error:
  //    ExpressionChangedAfterItHasBeenCheckedError:
  //      Expression has changed after it was checked.
  //      Previous value: 'ngIf: true'. Current value: 'ngIf: false'
  // (Note that the ngIf call in question was looking at the value of appName)
  ngAfterViewChecked(): void {
    this._changeDetector.detectChanges();
  }

  get showPhoneMenu(): boolean {
    return this.isLoggedIn || this._userInterfaceService.showPublicMenu;
  }

  get isRootApp(): boolean {
    return Globals.thisApplication === Globals.rootApplication;
  }

  get isAcctMgr(): boolean {
    return Globals.thisApplication === 'AccountManager';
  }

  get isGuest(): boolean {
    return this.userRole === 'guest';
  }

  get previousApp(): string {
    return this._userInterfaceService.previousApplication;
  }

  logout(): void {
    this._userAuthService.logout();
  }

  clickBannerMenu(): void {
    this._userInterfaceService.toggleBannerMenu();
  }

  clickSideNavMenu(): void {
    this._userInterfaceService.toggleSideNavMenu();
  }

  loadPage(pageName: string): void {
    this._userInterfaceService.loadPage(pageName);
  }

  goToAccountManagerApp(): void {
    this._userInterfaceService.goToAccountManager();
  }

  goToRootApp(): void {
    this._userInterfaceService.goToRootApp();
  }

  goToApp(appName: string): void {
    this._userInterfaceService.goToApp(appName);
  }

  returnToPreviousApp(): void {
    this._userInterfaceService.returnToPreviousApp();
  }

  goToPricing(): void {
    this._userInterfaceService.goToPricing();
  }

}
