import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ClientCoreRoutingModule } from 'src/app/client-core/client-core-routing.module';
import { CheckForResRepComponent } from 'src/app/client-core/pages/check-for-res-rep/check-for-res-rep.component';
import { AccountManagerCoreRoutingModule } from 'src/app/account-manager-core/account-manager-core-routing.module';
import { HomeOrgComponent } from './pages/home-org/home-org.component';

///////////////////////////////////////
// NOW set the routes...
///////////////////////////////////////

const appRoutes: Routes = [
  // loadChildren w/import() is the lazy-loading mechanism...
  // Make sure your import statements are clean!

  // Do NOT include root-level specific components here because the module routing is loaded in the imports below...

  // ROOT application is NOT listed here! Only web app sub-modules (for lazy loading...)
  // { path: 'AccountManager', loadChildren: () => import('src/app/account-manager/account-manager.module').then(m => m.AccountManagerModule) },

  // ** catch all paths you do NOT know as the LAST item on the list...
  { path: '**', component: CheckForResRepComponent, pathMatch: 'full' },

];


@NgModule({
  declarations: [
  ],
  // forRoot() is used ONLY at the top "root" level... (i.e. in appModule)
  // forChild() is used in "child" route modules...
  imports: [
    ClientCoreRoutingModule,
    AccountManagerCoreRoutingModule,

    RouterModule.forRoot(
      appRoutes
      , {
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
}
    ),
   ],
  exports: [
    RouterModule,
  ]
})

export class AppRoutingModule {
  constructor(
    userInterfaceService: UserInterfaceService
  ) {
    userInterfaceService.overRideAngularRouterPathComponent('', HomeOrgComponent);
  }
}
