<div class="wackadoo-main {{ (showMenu ? '' : 'marginTop0 marginBottom0')}}" wackadooMenuCloser>
    <div class="sideNavMenu" wackadooMenuPlaceholder>
        <wackadoo-menu [showMenu]="showMenu"></wackadoo-menu>
    </div>
    <div wackadooPagePlaceholder>
<!--
        <div *ngIf="screenType === 'phone'">{{pageName}}</div>
-->
        <wackadoo-account-message></wackadoo-account-message>
        <wackadoo-current-selection></wackadoo-current-selection>
        <!-- this is a div instead of an ng-container in order to survive multiple in-page changes -->
        <div wackadooAppHeaderPlaceholder></div>
        <div wackadooMaximizeContentHeight maxHeightThingBelowSelector=".wackadoo-footer" [maxHeightFudgeFactor]="-12">
            <!-- 
                All generated page content goes here. 
                Yes, we use the router mechanism to load pages.
            -->
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
