import { Component } from '@angular/core';
import { StringComponent } from '../string/string.component';

@Component({
  selector: 'wackadoo-field-email',
  templateUrl: './email.component.html',
})
export class EmailComponent extends StringComponent {

  onBlur(e: any): boolean {

    // FIRST we do normal "on blur" processing...

    super.forceValidValue(e);

    // THEN we double check a common email typo...

    if (this.f.value.toLowerCase().endsWith('.co')) {

      const confirmMsg = 'This email address ends with ".co", which *is* a valid top-level domain name.\n'
                        + 'Sometimes people type ".co" by mistake when they mean to type ".com".\n'
                        + '\nClick OK if ".co" is what you intended.'
                        + '\nClick Cancel to change it.';

      this.modalDialogService.showConfirm(confirmMsg, 'Double-Checking The Email Doman!').subscribe(
        (flag: boolean) => {
            if (!flag) {
              const target = e.srcElement || e.target;
              const cellInput = target;
              // then we throw focus back into the field...
              window.setTimeout(() => cellInput.focus(), 0);
            }
        }
      );
    }

    return false;
  }

}
