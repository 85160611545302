import { Injectable } from '@angular/core';
import { BusinessRules, EventRequestDefinition } from './business-rules.model';
import { SelectedResource } from '../ui/main/current-selection/selected-resource.model';
import { User } from '../data/user.model';
import { WEvent } from '../data/event.model';
import { Globals } from './global.service';
import { ScreenType } from './screen-type.enum';

export class WMenuItem {
  name: string = null;
  subMenuItems?: string [] = null;

  constructor(name: string, subMenuItems?: string []) {
    this.name = name;
    if (subMenuItems) {
      this.subMenuItems = subMenuItems;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class BusinessRuleService {

  private _businessRules: { [appName: string]: BusinessRules} = {};

  get businessRules(): BusinessRules {
    // this.appName is undefined at initial startup...
    const apNm = (this.appName ? this.appName : Globals.rootApplication );
    return this._businessRules[apNm];
  }

  appName = null;

  constructor() {
    this.appName = Globals.thisApplication;
  }

  loadRules(appName: string, businessRules: BusinessRules): void {

    this._businessRules[appName] = businessRules;

    // these pages are common across virtually ALL apps
    this._businessRules[appName].otherMenuItems.push('BackupRestore');
    this._businessRules[appName].otherMenuItems.push('CustomTemplates');

  }

  isPublicPage(pageName: string): boolean {

    // Pricing, login, contact-us, about, policy, value, and help pages are always fair game
    // as well as anything configured for this specific web app.

    const flag = this._businessRules[this.appName].otherPublicPages.includes(pageName)
                  || (pageName === 'Pricing')
                  || (pageName === 'login')
                  || (pageName === 'home')
                  || (pageName === 'contact-us')
                  || pageName.startsWith('about-')
                  || pageName.startsWith('value-')
                  || pageName.startsWith('policy-')
                  || pageName.startsWith('help-')
                ;

    // console.log('isPublicPage()', pageName, flag);

    return flag;
  }

  getPurchaserRole(appName: string): string {
    return (this._businessRules[appName] ? this._businessRules[appName].purchaserRole : 'manager');
  }

  getPostLoginStartUpPage(userRole: string): string {
    // console.log('BusinessRules.getPostLoginStartUpPage()', userRole, Globals.thisApplication, Globals.rootApplication);
    // If the ROOT app is AcctMgr, then allnonsysadmin users land on the 'home' page, which shows them their apps...
    if (
        (userRole !== 'sysadmin')
        &&
        (Globals.thisApplication === Globals.rootApplication)
        &&
        (Globals.rootApplication === 'AccountManager')
    ) {
      return('home');
    }
    return(this.getFirstEHPage(userRole));
  }

  getFirstEHPage(userRole: string): string {
    let firstPage = 'home';

    if (typeof this.businessRules.firstEHPage === 'string') {
      firstPage = this.businessRules.firstEHPage;
    } else if (typeof this.businessRules.firstEHPage === 'object') {
      let temp = this.businessRules.firstEHPage[userRole];
      if (!temp) {
        temp = this.businessRules.firstEHPage['*'];
      }
      if (temp) {
        firstPage = temp;
      }
    }
    // console.log('BusinessRules.getFirstEHPage()', userRole, this.businessRules.firstEHPage, typeof this.businessRules.firstEHPage, firstPage);
    return(firstPage);
}

  getDefaultPageSize(ehName: string): number {
    // console.log('BusinessRules.getDefaultPageSizes('+ehName+')\n' + JSON.stringify(this.businessRules.defaultPageSizes));
    let defaultPageSize = 10;
    if (this.businessRules.defaultPageSizes.hasOwnProperty(ehName)) {
      defaultPageSize = this.businessRules.defaultPageSizes[ehName];
    }
    return(defaultPageSize);
  }

  getDefaultAction(ehName: string): string {
    // console.log('BusinessRules.getDefaultAction('+ehName+')\n' + JSON.stringify(this.businessRules.defaultActions));
    let defaultAction: string = null;
    if (this.businessRules.defaultActions.hasOwnProperty(ehName)) {
      defaultAction = this.businessRules.defaultActions[ehName];
    }
    return(defaultAction);
  }

  allParmsMatch(parms: any, ruleParms: any): boolean {
    let flag = false;
    let parmsAreEmpty = true;

    // console.log('allParmsMatch', parms, ruleParms)

    // if there are no ruleParms, this does not have anything to match...
    // so we leave flag false and parmsAreEmpty true...

    if (ruleParms) {
      for (const [name] of Object.entries(ruleParms)) {
        parmsAreEmpty = false;
        const ruleParmValue = ruleParms[name];
        const parmValue = parms[name];
        if (ruleParmValue === parmValue) {
          flag = true;
        } else if ((ruleParmValue === '*') && (parmValue !== null) && (parmValue !== '')) {
          flag = true;
        } else {
          flag = false;
          break;
        }
      }
    }

    return(flag || parmsAreEmpty);
  }

  private _userRoleMatchesMenuRole(userRole: string, menuRole: string): boolean {
    if (!menuRole || !userRole) {
      return false;
    }

    let flag = (menuRole === '') || (menuRole === '*');

    const roleOrAbove = menuRole.endsWith('+');
    const roleOrBelow = menuRole.endsWith('-');

    const baseMenuRole = (roleOrAbove || roleOrBelow ? menuRole.substring(0, menuRole.length - 1) : menuRole);

    if (roleOrAbove) {
      switch (baseMenuRole) {
        case 'public' :
          flag = flag || (userRole === 'public');
        case 'authorized' :
          flag = flag || (userRole === 'authorized');
        case 'guest' :
          flag = flag || (userRole === 'guest');
        case 'member' :
          flag = flag || (userRole === 'member');
        case 'staff' :
          flag = flag || (userRole === 'staff');
        case 'manager' :
          flag = flag || (userRole === 'manager');
        case 'director' :
          flag = flag || (userRole === 'director');
        case 'admin' :
          flag = flag || (userRole === 'admin');
        case 'sysadmin' :
          flag = flag || (userRole === 'sysadmin');
      }
    } else if (roleOrBelow) {
      switch (baseMenuRole) {
        case 'sysadmin' :
          flag = flag || (userRole === 'sysadmin');
        case 'admin' :
          flag = flag || (userRole === 'admin');
        case 'director' :
          flag = flag || (userRole === 'director');
        case 'manager' :
          flag = flag || (userRole === 'manager');
        case 'staff' :
          flag = flag || (userRole === 'staff');
        case 'member' :
          flag = flag || (userRole === 'member');
        case 'guest' :
          flag = flag || (userRole === 'guest');
        case 'authorized' :
          flag = flag || (userRole === 'authorized');
        case 'public' :
          flag = flag || (userRole === 'public');
      }
    } else { // if (exact) {
      flag = flag || (menuRole === userRole);
    }

    // console.log('BusinessRules()._userRoleMatchesMenuRole(' + userRole + ', ' + menuRole + ') - baseMenuRole: ' + baseMenuRole, 'match? ' + flag);

    return flag;
  }

  private _screenTypeMatches(screenType: ScreenType, menuScreenType: string): boolean {
    if (!menuScreenType || !screenType) {
      return true;
    }

    let flag = (menuScreenType === '') || (menuScreenType === '*');

    const screenTypeOrAbove = menuScreenType.endsWith('+');
    const screenTypeOrBelow = menuScreenType.endsWith('-');

    const baseMenuScreenType = (screenTypeOrAbove || screenTypeOrBelow ? menuScreenType.substring(0, menuScreenType.length - 1) : menuScreenType);

    if (screenTypeOrAbove) {
      switch (baseMenuScreenType) {
        case 'phone' :
          flag = flag || (screenType === 'phone');
        case 'tablet' :
          flag = flag || (screenType === 'tablet');
        case 'fullscreen' :
          flag = flag || (screenType === 'fullscreen');
      }
    } else if (screenTypeOrBelow) {
      switch (baseMenuScreenType) {
        case 'fullscreen' :
          flag = flag || (screenType === 'fullscreen');
        case 'tablet' :
          flag = flag || (screenType === 'tablet');
        case 'phone' :
          flag = flag || (screenType === 'phone');
      }
    } else { // if (exact) {
      flag = flag || (menuScreenType === screenType);
    }

    // console.log('BusinessRules()._screenTypeMatches(' + screenType + ', ' + menuScreenType + ') - baseMenuScreenType: ' + baseMenuScreenType, 'match? ' + flag);

    return flag;
  }

  applyMenuRules(user: User, selectedResources: {[ehName: string]: SelectedResource}, serverAPI: any [], screenType?: ScreenType): WMenuItem [] {
    const listOfMenuItemsToReturn: WMenuItem [] = [];

    // console.log('=================================');
    // console.log('BusinessRules.applyMenuRules() - ' + user.role, user, this.businessRules.menuRules, serverAPI);

    // if we're ONLY showing the user a single page, that makes up the whole menu...
    if (user.guestPage !== null) {
      listOfMenuItemsToReturn.push(new WMenuItem(user.guestPage));
    } else {
      const rules = this.businessRules.menuRules;
      for (const rule of rules) {
        // console.log('BusinessRules().applyMenuRules() - examining menu rule...', rule, rule._selection, 'role match? ' + this._userRoleMatchesMenuRole(user.role, rule._role), selectedResources, selectedResources[rule._selection]);
        if (
            ((rule._selection === '') || (rule._selection === '*') || selectedResources[rule._selection])
            && this._userRoleMatchesMenuRole(user.role, rule._role)
            && this._screenTypeMatches(screenType, rule._screenType)
        ) {
          for (const menuItemName in rule) {
            if (rule[menuItemName] && !menuItemName.startsWith('_')) {

              const menuRole = rule[menuItemName].role;
              const menuScreenType = rule[menuItemName].screenType;

              if (menuRole
                  && this._userRoleMatchesMenuRole(user.role, menuRole)
                  && this._screenTypeMatches(screenType, menuScreenType)
              ) {
                if (this.businessRules.otherMenuItems.includes(menuItemName)) {
                  // console.log('BusinessRules().applyMenuRules() - adding OTHER menu item: ' + menuItemName);
                  listOfMenuItemsToReturn.push(new WMenuItem(menuItemName));
                } else {
                  for (const ehAPI of serverAPI) {
                    if (menuItemName === ehAPI['@name']) {
                      // console.log('BusinessRules().applyMenuRules() - adding EH menu item: ' + menuItemName);
                      listOfMenuItemsToReturn.push(new WMenuItem(menuItemName));
                    }
                  }
                }
              }

              const subMenu = rule[menuItemName].subMenu;

              if (subMenu) {
                const filteredSubMenu: string [] = [];
                for (const subMenuItemName in subMenu) {
                  if (subMenu[subMenuItemName]) {

                    const subMenuRole = subMenu[subMenuItemName].role;
                    const subMenuScreenType = subMenu[subMenuItemName].screenType;

                    if (subMenuRole
                        && this._userRoleMatchesMenuRole(user.role, subMenuRole)
                        && this._screenTypeMatches(screenType, subMenuScreenType)
                    ) {
                      if (this.businessRules.otherMenuItems.includes(subMenuItemName)) {
                        filteredSubMenu.push(subMenuItemName);
                      } else {
                        for (const ehAPI of serverAPI) {
                          if (subMenuItemName === ehAPI['@name']) {
                            filteredSubMenu.push(subMenuItemName);
                          }
                        }
                      }
                    }
                  }
                }
                if (filteredSubMenu.length > 0) {
                  // console.log('BusinessRules().applyMenuRules() - adding sub-menu: ' + menuItemName, filteredSubMenu);
                  listOfMenuItemsToReturn.push(new WMenuItem(menuItemName, filteredSubMenu));
                }
              }
            }
          }
          break;
        }
      }

      // console.log('BusinessRules().applyMenuRules() - pre-selection-exam', listOfMenuItemsToReturn);

      // Now, if we have something selected that is NOT in the rule, display it...

      if (!user.isGuestOrBelow) {
        for (const ehAPI of serverAPI) {
          const ehName = ehAPI['@name'];
          if (selectedResources[ehName]) {
            // console.log('BusinessRules().applyMenuRules() - checking selected item: ' + ehName, listOfMenuItemsToReturn, listOfMenuItemsToReturn.find(mi => mi.name === ehName) || (mi.subMenuItems.includes(ehName)));
            // gotta check menuItems AND their sub-menus
            if (!listOfMenuItemsToReturn.find(mi => (mi.name === ehName) || (mi.subMenuItems && mi.subMenuItems.includes(ehName)))) {
              // console.log('BusinessRules().applyMenuRules() - adding NON-RULE (selected) menu item: ' + ehName);
              listOfMenuItemsToReturn.push(new WMenuItem(ehName));
            }
          }
        }
      }
    }

    // console.log('BusinessRules().applyMenuRules() - exiting', listOfMenuItemsToReturn, '\n=================================');

    return listOfMenuItemsToReturn;
  }

  getMenuPageNameForCurrentSelections(user: User, selectedResources: {[ehName: string]: SelectedResource}, screenType: ScreenType): string {

    // console.log('BusinessRules.getMenuPageNameForCurrentSelections() - ' + user.role, user, this.businessRules.menuRules);

    const rules = this.businessRules.menuRules;
    for (const rule of rules) {
      // console.log('BusinessRules().getMenuPageNameForCurrentSelections() - examining menu rule...', rule, rule._selection, this.userRoleMatchesMenuRole(rule._role, user.role), selectedResources, selectedResources[rule._selection]);
      if (
          (rule._selection !== '')
          && (rule._selection !== '*')
          && selectedResources[rule._selection]
          && this._userRoleMatchesMenuRole(user.role, rule._role)
          && this._screenTypeMatches(screenType, rule._screenType)
      ) {
        return rule._selection;
      }
    }

    return null;
  }

  /**
   * This returns true if the given resource keys would display the given target EH...
   */
  checkMenuRules(user: User, selectedResourceKeys: {[ehName: string]: SelectedResource}, serverAPI: any, targetEHName: string, screenType?: ScreenType): boolean {
    let flag = false;
    // console.log('BusinessRules.checkMenuRules()\n' + JSON.stringify(selectedResourceKeys));
    const menuItems = this.applyMenuRules(user, selectedResourceKeys, serverAPI, screenType);
    // console.log('menu.buildMenu()', this._userAuthService.userAuthChanged.getValue(), this._userInterfaceService.currentSelections, this._eventServerService.userAPI, this.pages);
    for (const mi of menuItems) {
      if (mi.name === targetEHName) {
        flag = true;
        break;
      }
      if (mi.subMenuItems && mi.subMenuItems.includes(targetEHName)) {
        flag = true;
        break;
      }
    }

    return(flag);
  }

  hasPublicMenu(): boolean {
    let flag = false;
    if (this.businessRules) { // at startup, this is not always set...
      const rules = this.businessRules.menuRules;
      for (const rule of rules) {
        if (rule._role.startsWith('public')) {
          flag = true;
          break;
        }
      }
    }
    return flag;
  }

  getPublicStartUpPage(): string {
    let firstPage = null;

    if (typeof this.businessRules.firstEHPage === 'object') {
      const temp = this.businessRules.firstEHPage.public;
      if (temp) {
        firstPage = temp;
      }
    }
    // console.log('BusinessRules.getFirstEHPage()', userRole, this.businessRules.firstEHPage, typeof this.businessRules.firstEHPage, firstPage);
    return(firstPage);
  }

  getMenuIcon(ehName: string): string {
    let icon = 'square';
    if (this.businessRules.menuIcons.hasOwnProperty(ehName)) {
      icon = this.businessRules.menuIcons[ehName];
    }
    return icon;
  }

  /**
   * response is {eh:newEH, action:newAction, parms:newParms}
   */
  applyServerRequestRules(eh: string, action: string, parms: any): EventRequestDefinition {
    parms = typeof parms !== 'undefined' ? parms : {};

    // a simple, immediately-return-the-value kind of Promise...
    let eventRequest: EventRequestDefinition = {eh, action, parms};

    // console.log('BusinessRules() - ALL request rules...', this._businessRules);
    // console.log('BusinessRules() - THIS APP request rules...', this.businessRules);
    // console.log('BusinessRules() - examining server request rules...', this.businessRules.serverRequestRules);
    for (const rule of this.businessRules.serverRequestRules) {
      // console.log('BusinessRules() - examining request rule...', rule);
      if (
          ((rule.eh === '*') || (rule.eh === eh)) &&
          ((rule.action === '*') || (rule.action === action)) &&
          (this.allParmsMatch(parms, rule.parms))
        ) {
          // console.log('BusinessRules() - firing request rule...', rule);
          eventRequest = rule.fn(eh, action, parms);
          break;
      }
    }
    return eventRequest;
  }

  /**
   * This allows us to do rule-based post-event filtering on server requests...
   */
  applyServerResponseRules(event: WEvent): WEvent {

    let fn = (event2: WEvent) => {
        return event2;
    };

    // console.log('BusinessRules() - examining response rules...\n' + JSON.stringify(this._serverResponseRules));
    for (const rule of this.businessRules.serverResponseRules) {
      // console.log('BusinessRules() - examining response rule...\n' + JSON.stringify(rule));
      if ( ((rule.eh === '*') || (rule.eh === event.firstHandler)) &&
           ((rule.action === '*') || (rule.action === event.action)) &&
           (event.status === 'OK')
      ) {
          // console.log('BusinessRules() - firing response rule...\n' + JSON.stringify(rule));
          fn = rule.fn;
          break;
      }
    }
    const mungedEvent = fn(event);
    // console.log('BusinessRules() - munged event...\n' + JSON.stringify(mungedEvent));
    return mungedEvent;
  }

  readOnlyOnce(ehName: string): boolean {
    return (
      this.businessRules.readOnlyOnceRules.includes(ehName)
      // && (user !== null) && (user.role === 'guest')
    );
  }

  applyMenuClickRules(eh: string, action: string): void {
    // console.log('BusinessRules() - examining menu click rules...\n' + JSON.stringify(this.businessRules.menuClickRules));
    for (const rule of this.businessRules.menuClickRules) {
      // console.log('BusinessRules() - examining menu click rule...\n' + JSON.stringify(rule));
      if ( ((rule.eh === '*') || (rule.eh === eh)) &&
            ((rule.action === '*') || (rule.action === action))
        ) {
          // console.log('BusinessRules() - firing menu click rule...\n' + JSON.stringify(rule));
          rule.fn(eh, action);
      }
    }
  }

  getForeignKeysThatMustBePopulated(ehName: string): any [] {
    let foreignTypeArray = [];
    // console.log('BusinessRules.getForeignKeysThatMustBePopulated() - ' + ehName + '\n' + JSON.stringify(this.businessRules.foreignKeysThatMustBePopulated));
    if (this.businessRules.foreignKeysThatMustBePopulated.hasOwnProperty(ehName)) {
      foreignTypeArray = this.businessRules.foreignKeysThatMustBePopulated[ehName];
    } else if (this.businessRules.foreignKeysThatMustBePopulated.hasOwnProperty('*')) {
      foreignTypeArray = this.businessRules.foreignKeysThatMustBePopulated['*'];
    }
    return(foreignTypeArray);
  }

  isForeignKeyThatMustBePopulated(ehName: string, foreignType: string): boolean {
    let flag = false;
    const foreignTypeArray = this.getForeignKeysThatMustBePopulated(ehName);
    // console.log('BusinessRules.isForeignKeyThatMustBePopulated(' + ehName + ', ' + foreignType + ')\n' + JSON.stringify(foreignTypeArray));
    if (foreignTypeArray) {
      flag = foreignTypeArray.includes(foreignType);
    }
    return(flag);
  }

  reportSystemTemplateIsEditable(reportTemplateName: string): boolean {
    return this.businessRules.editableReportSystemTemplates.indexOf(reportTemplateName) !== -1;
  }

}
