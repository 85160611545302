<span *ngIf="f">
    <ng-container *ngIf="mode === 'read' ; else writeMode">
        <audio controls>
            <source
                src="{{ url }}"
                type="audio/mpeg"
            />
            Your browser does not support the audio tag.
        </audio>
    </ng-container>
    <ng-template #writeMode>
        <div class="d-flex justify-content-between">
            <div *ngIf="f.changed">
                <div class="fieldChanged">
                    New Audio File Is Now Cached...
                </div>
                <div class="italic">
                    (It will be uploaded when you click "Save"...)
                </div>
                <div class="marginTopHalfEm">
                    <span class="bold">
                        {{ f.fileName }}
                    </span>
                    ({{ f.fileSize }} bytes)
                </div>
                <div>
                    {{ f.fileType }}
                </div>
                <div>
                    File Last Modified: {{ f.fileDate.toLocaleString() }}
                </div>
            </div>
            <div *ngIf="!f.changed">
                <button 
                    class="btn btn-secondary"

                    wackadooFileRead
                    [newFileContent]="loadFileContent"
                    [validExtensions]="validExtensions"
                    [maxFileSizeMB]="f.maxFileSizeMB"
                >
                    Upload Audio File
                </button>
                <div>
                    (click button, or drop a {{formattedValidExtensions}} audio file here...) 
                </div>
            </div>
            <div *ngIf="f.isPopulated">
                <button type="button" class="btn btn-secondary" title="Clear Audio File Contents" (click)="clearBinaryContent()" ><span class="fas fa-trash-alt"></span> Clear Audio File</button>
            </div>
        </div>
    </ng-template>
</span>
