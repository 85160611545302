import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { DetailContentComponent } from '../../repository-page/detail-content/detail-content.component';
import { WSelect, WField } from 'src/app/client-core/data/field.model';
import { Subject, Subscription } from 'rxjs';
import { WEvent } from 'src/app/client-core/data/event.model';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { HtmlEditorService } from 'src/app/client-core/services/html-editor.service';
import { FieldMode } from 'src/app/client-core/data/field/field-mode.model';

@Component({
  selector: 'wackadoo-e-mail-template-detail-content',
  templateUrl: './e-mail-template-detail-content.component.html',
})
export class EMailTemplateDetailContentComponent extends DetailContentComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'EMailTemplateDetailContentComponent';

  resourceType: WSelect = null;

  resourceTypeChangeSubject: Subject<WField> = null;
  resourceTypeChangeSubscription: Subscription = null;

  fieldNames: string [] = [];
  selectedFieldName: string = null;

  wysiwyg: boolean;
  wysiwygSubscription: Subscription;

  mouseUpSubject: Subject<any> = null;
  mouseUpSubscription: Subscription = null;

  constructor(
    eventServerService: EventServerService,
    userInterfaceService: UserInterfaceService,
    public htmlEditorService: HtmlEditorService,
    public renderer: Renderer2,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.mode = this.userInterfaceService.resourceDetailPageDisplayMode.getValue();

    // IMPORTANT NOTE: wackadoo-field-binary uses the fileType attribute of the
    // binary field to properly display field contents in read and write mode...

    // So we properly initialize the extension and fileType when empty, set to
    // improper suffix value, or when we don't have any file content at all...

    if (
        (!this.resource.templateFileContent.value)
        || (this.resource.templateExtension.value === null)
        || this.resource.templateExtension.value.startsWith('email.')
    ) {
      this.resource.templateExtension.value = this.resource.templateExtension.default;
      this.resource.templateFileContent.fileType = this.resource.templateExtension.default;
    }

    if (this.action === 'copy')  {
      this.resource.systemTemplate.value = false;
      this.resource.templateName.value = '';
    }

    // now we set up the resource field lookups...

    this.fieldNames = [];

    if (this.resourceType) {
      // check to see if there was one selected previously for this template...
      const oldResourceType = this.userInterfaceService.getPageState('EMailTemplates', this.resource.templateName.value + '-resourceType');
      this.resourceType.value = (oldResourceType ? oldResourceType : this.resourceType.value);

      this.loadFieldNames(this.resourceType.value);
    } else {
      this.loadResourceTypes();
    }

    this.resourceTypeChangeSubject = new Subject<WField>();

    this.resourceTypeChangeSubscription = this.resourceTypeChangeSubject.subscribe(
      (f: WField) => {
        const resourceType = f.value;
        this.userInterfaceService.setPageState('EMailTemplates', this.resource.templateName.value + '-resourceType', resourceType);
        this.loadFieldNames(resourceType);
      }
    );

    this.mouseUpSubject = new Subject<any>();

    this.mouseUpSubscription = this.mouseUpSubject.subscribe(
      (e: any) => {
        if (this.payload && (this.payload !== '')) {
          this.insertPayloadIntoTemplate(e);
        }
      }
    );

  }

  ngOnDestroy(): void {
    if (this.resourceTypeChangeSubscription) {
      this.resourceTypeChangeSubscription.unsubscribe();
    }
    if (this.mouseUpSubscription) {
      this.mouseUpSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }

  loadResourceTypes(): void {
    this.eventServerService.fireEvent('EMailTemplates', 'listTemplateResourceTypes', {}).subscribe(
      (event: WEvent) => {

        if (event.status !== 'OK') {
          throw new Error('Unable to load resource types for EMailTemplates');
        }

        let resourceTypeSelect = '';
        for (const r of event.resources) {
          if (r.resourceType) {
            if (resourceTypeSelect.length === 0) {
              this.resourceType = new WSelect('resourceType', r.resourceType.value);
            }
            resourceTypeSelect += ('|' + r.resourceType.value);
          }
        }

        // trim off the leading delimiter
        this.resourceType.select = resourceTypeSelect.substr(1);

        // check to see if there was one selected previously for this template...
        const oldResourceType = this.userInterfaceService.getPageState('EMailTemplates', this.resource.templateName.value + '-resourceType');
        this.resourceType.value = (oldResourceType ? oldResourceType : this.resourceType.value);

        this.loadFieldNames(this.resourceType.value);

      }
    );
  }

  loadFieldNames(resourceType: string): void {

    this.fieldNames = [];

    const eh = this.eventServerService.getEventHandlerForResourceType(resourceType);
    const fields = this.eventServerService.getFieldDefinitionsInOrder(eh);

    for (const f2 of fields) {
      this.fieldNames.push(f2.name);
    }
  }

  get payload(): string {
    return (this.selectedFieldName ? '{' + this.selectedFieldName + '}' : null);
  }

  selectFieldName(fieldName?: string): void {
    fieldName = typeof fieldName === 'string' ? fieldName : null;
    if (this.mode === FieldMode.write) {
      this.selectedFieldName = fieldName;
    }
  }

  ////////////////////////////////////////////////
  // This is the "insert field name" method...
  ////////////////////////////////////////////////

  insertPayloadIntoTemplate(e: any): void {
    try {

      if (this.payload && (this.payload !== '')) {

        // XHTML edit mode

        if (e.type === 'mouseup') {

          e.stopPropagation();
          e.preventDefault();

          const target: HTMLTextAreaElement = e.target;

          const selectionOffset = target.selectionStart;

          if (selectionOffset || (selectionOffset === 0)) {
            target.value = target.value.substring(0, selectionOffset)
                            + this.payload
                            + target.value.substring(target.selectionEnd, target.value.length);
          } else {
            target.value += this.payload;
          }

          // put the cursor at the end of the inserted payload...
          target.selectionStart = selectionOffset + this.payload.length;
          target.selectionEnd = selectionOffset + this.payload.length;

          // now trigger the binary html field's TEXT/HTML editor change event handler...
          target.dispatchEvent(new Event('input'));

        // WYSIWYG edit mode (TinyMCE)

        } else if (e.event.type === 'mouseup') {

          // e is a TinyMCE "event" made up of the "editor" and the actual "event"...

          e.event.stopPropagation();
          e.event.preventDefault();

          // Here's how to insert the payload w/TinyMCE
          // per https://stackoverflow.com/questions/13393892/inserting-text-in-tinymce-editor-where-the-cursor-is
          e.editor.execCommand('mceInsertContent', false, this.payload);

          // now trigger the binary html field's WYSIWYG editor change event handler...
          e.editor.fire('change');

        }

        // and un-select the field name to prevent inadvertant multiple insertions
        this.selectFieldName();

      }

    } catch (ex) {
      const msg = 'EMailTemplates.onTextAreaMouseUp()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }

  }

}
