import { Component, OnInit, OnDestroy, Renderer2, Input } from '@angular/core';
import { StringComponent } from '../string/string.component';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { UtilityLibService } from 'src/app/client-core/services/utility-lib.service';

@Component({
  selector: 'wackadoo-field-phone',
  templateUrl: './phone.component.html',
})
export class PhoneComponent extends StringComponent implements OnInit, OnDestroy {

  @Input() forceUSAreaCodePlus7Number = false;

  constructor(
    renderer: Renderer2,
    modalDialogService: ModalDialogService,
    public utilsLib: UtilityLibService,
  ) {
    super(renderer, modalDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  forceValidValue(e: any): boolean {
    // e = e || window.event;
    const target = e.srcElement || e.target;

    const cellInput = target;

    if (this.f.changed) {
      if (!this.f.isValidValue(cellInput.value)) {

        this.modalDialogService.showAlert('Please enter a valid value.', 'Invalid Data Entry').subscribe(
          () => {
            // throw focus back into the field...
            window.setTimeout(() => cellInput.focus(), 0);
          }
        );

      // This is the extra check in case we want to limit the number to a full, US-style "3 + 7" (area code + number)
      } else if (this.forceUSAreaCodePlus7Number && (this.utilsLib.keepOnlyAlphaNumerics(this.f.value).length !== 10)) {

        this.modalDialogService.showAlert('Please enter a valid US-style, "area code + number" value for <strong>' + this.f.name + '</strong>. (e.g. nnn nnn nnnn)\n\nNote that you can format it however you like - with or without parentheses, spaces, dashes, etc. - but it must be a valid "local" US phone number containing exactly 10 digits.', 'Invalid Phone Number').subscribe(
          () => {
            // throw focus back into the field...
            window.setTimeout(() => cellInput.focus(), 0);
          }
        );

      }
    }

    return false;
  }

}
