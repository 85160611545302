<div class="border-bottom">
  <div class="left d-flex justify-content-between" *ngIf="resource">
    <div class="" [ngClass]="{ 'd-flex flex-row' : (screenType !== 'phone') }" >
      <div class=""  [ngClass]="{ 'marginHalfEm' : (screenType !== 'phone') ,  'marginQuarterEm' : (screenType === 'phone') }" >
        <wackadoo-field [f]="resource.itemName" mode="read"></wackadoo-field>
      </div>
      <div class="small" [ngClass]="{ 'marginHalfEm marginTopWholeEm' : (screenType !== 'phone') ,  'marginBottomHalfEm' : (screenType === 'phone') }" >
        <ul class="" [ngClass]="{ 'smallish' : (screenType === 'phone') }">
          <li *ngIf="resource.description1.isPopulated">
            <wackadoo-field [f]="resource.description1" mode="read"></wackadoo-field>
          </li>
          <li *ngIf="resource.description2.isPopulated">
            <wackadoo-field [f]="resource.description2" mode="read"></wackadoo-field>
          </li>
          <li *ngIf="resource.description3.isPopulated">
            <wackadoo-field [f]="resource.description3" mode="read"></wackadoo-field>
          </li>
          <li *ngIf="resource.description4.isPopulated">
            <wackadoo-field [f]="resource.description4" mode="read"></wackadoo-field>
          </li>
          <li *ngIf="resource.description5.isPopulated">
            <wackadoo-field [f]="resource.description5" mode="read"></wackadoo-field>
          </li>
          <li *ngIf="resource.description6.isPopulated">
            <wackadoo-field [f]="resource.description6" mode="read"></wackadoo-field>
          </li>
          <li *ngIf="resource.description7.isPopulated">
            <wackadoo-field [f]="resource.description7" mode="read"></wackadoo-field>
          </li>
          <li *ngIf="resource.description8.isPopulated">
            <wackadoo-field [f]="resource.description8" mode="read"></wackadoo-field>
          </li>
          <li *ngIf="resource.description9.isPopulated">
            <wackadoo-field [f]="resource.description9" mode="read"></wackadoo-field>
          </li>
          <li *ngIf="resource.description10.isPopulated">
            <wackadoo-field [f]="resource.description10" mode="read"></wackadoo-field>
          </li>
          <li class="italic" *ngIf="resource.quantityAdjustable.value">
            (Enter {{((resource.resourceType.value === 'Cash') ? 'donation amount' : 'quantity')}} in shopping cart)
          </li>
          <li class="italic" *ngIf="resource.fixedInventory.value">
            ({{resource.totalInventory.value - resource.reservedInventory.value}} available)
          </li>
        </ul>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="marginHalfEm">
        <wackadoo-field [f]="resource.itemPrice" mode="read"></wackadoo-field>
      </div>
      <div class="marginHalfEm">
        <button 
          type="button" title="Add To Cart" data-abbrev="+" (click)="addItemToShoppingCart(resource)" 
          class="btn btn-secondary actionButton small paddingTopQuarterEm paddingBottomQuarterEm paddingLeftHalfEm paddingRightHalfEm" 
          [disabled]="(mode === 'read') || ((resource.fixedInventory.value === true) && (resource.totalInventory.value === resource.reservedInventory.value))"
        ><span class="fas fa-plus"></span></button>
      </div>
    </div>
  </div>
</div>
