import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'src/app/client-core/services/global.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ScreenType } from 'src/app/client-core/services/screen-type.enum';

@Component({
  selector: 'wackadoo-carousel',
  templateUrl: './carousel.component.html',

  encapsulation: ViewEncapsulation.None,  // required for fade CSS to work...
  providers: [
    NgbCarouselConfig
  ]
})
export class CarouselComponent implements OnInit, AfterViewInit {

  @Input() smallVersion = false;

  screenType: ScreenType = null;
  domain: string;
  appName: string;

  images: string [];

  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  constructor(
    private _userInterfaceService: UserInterfaceService,
    private _carouselConfig: NgbCarouselConfig,
    private _elementRef: ElementRef,
  ) {
    this._carouselConfig.showNavigationArrows = false;
    this._carouselConfig.showNavigationIndicators = false;
    this._carouselConfig.pauseOnHover = false;
    this._carouselConfig.keyboard = false;
  }

  ngOnInit(): void {

    this.screenType = this._userInterfaceService.screenType.getValue();

    this.domain = Globals.domain;
    this.appName = Globals.thisApplication;
    this.images = [1, 2, 3, 4].map((i) => './assets/pics/' + this.domain + '.carousel.' + i + '.jpg');

    // console.log('appName: ' + this.appName);
  }

  ngAfterViewInit(): void {

    if (this.images) {
      for (let idx = 0; idx < this.images.length; idx++) {

        const imageUrl = './assets/pics/' + this.domain + '.carousel.' + (idx + 1) + '.jpg';

        // console.log(imageUrl);

        let backgroundImage = 'linear-gradient(';
        backgroundImage += 'to right, ';
        backgroundImage += 'rgba(255, 255, 255, 0) 0%, ';
        backgroundImage += 'rgba(255, 255, 255, 0) 45%, ';
        backgroundImage += 'rgba(255, 255, 255, 0.75) 60%, ';
        backgroundImage += 'rgba(255, 255, 255, 1) 100%), ';
        backgroundImage += 'url("' + imageUrl + '")';

        const fadedImage = this._elementRef.nativeElement.querySelector('.faded-image.image' + idx);
        // we don't get one of these (check ngIf at top of html template!)
        if (fadedImage) {
          fadedImage.style.backgroundImage = backgroundImage;
          fadedImage.style.backgroundRepeat = 'no-repeat';
          fadedImage.style.backgroundPosition = 'center';
        }

      }
    }

  }

}
