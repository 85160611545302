import { Component, OnInit, OnDestroy, Renderer2, ElementRef } from '@angular/core';
import { FieldComponent } from '../field.component';
import { ModalDialogService } from '../../../services/modal-dialog.service';

@Component({
  selector: 'wackadoo-field-encrypted',
  templateUrl: './encrypted.component.html',

})
export class EncryptedComponent extends FieldComponent implements OnInit, OnDestroy {

  onBlurEventListenerFunction: any;

  mainEncryptedInput: HTMLInputElement = null;
  otherEncryptedInput: HTMLInputElement = null;

    constructor(
    renderer: Renderer2,
    modalDialogService: ModalDialogService,
    public elementRef: ElementRef,
  ) {
    super(renderer, modalDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.mainEncryptedInput = this.elementRef.nativeElement.querySelector('input[name="' + this.f.name + '"]');
    this.otherEncryptedInput = this.elementRef.nativeElement.querySelector('input[name="' + this.f.name + 'Repeat"]');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onBlurOtherInput(): void {

    // throw focus back into the field...

    let message = '';
    let good = false;

    // we do the check at blur time to see if we have an error

    if (this.f.changed) {
      if (!this.f.isValidValue(this.mainEncryptedInput.value)) {
        // this branch happens when we have a bad PW in the main PW field,
        // and we tab out of it into the other PW field, and the code forces
        // focus back to the main PW field FROM the other PW field, causing
        // this "blur" event to fire...
          message = 'Value does not pass field validation, please try again...';
      } else if (this.otherEncryptedInput.value === this.mainEncryptedInput.value) {
        if (this.f.isValidValue(this.otherEncryptedInput.value)) {
          good = true;
        } else {
          message = 'Value does not pass field validation, please try again...';
        }
      } else {
        message = 'Values do not match, please try again...';
      }

      // console.log('on blur() - good: ' + good + ', message: ' + message);

      if (!good) {
        this.modalDialogService.showAlert(message, 'Bad Input!').subscribe(
          () => {
            this.otherEncryptedInput.value = '';
            this.otherEncryptedInput.focus();
          }
        );

      }
    }
  }

  onInputChangeOtherInput(): boolean {

    // clear the previous formatting, if present...
    this.renderer.removeClass(this.mainEncryptedInput, 'fieldError');
    this.renderer.removeClass(this.mainEncryptedInput, 'fieldChanged');
    this.renderer.removeClass(this.otherEncryptedInput, 'fieldError');
    this.renderer.removeClass(this.otherEncryptedInput, 'fieldChanged');

    // console.log('otherPasswordInput.value: ' + otherPasswordInput.value + ', mainPasswordInput.value: ' + mainPasswordInput.value);

    let good = false;
    let message = '';

    if (this.otherEncryptedInput.value === this.mainEncryptedInput.value) {
      if (this.f.isValidValue(this.otherEncryptedInput.value)) {
        good = true;
      } else {
        message = 'Value does not pass field validation, please try again...';
      }
    } else {
      message = 'Values do not match, please try again...';
    }

    // console.log('good: ' + good + ', message: ' + message);

    if (good) {
      this.renderer.addClass(this.otherEncryptedInput, 'fieldChanged');
      this.renderer.addClass(this.mainEncryptedInput, 'fieldChanged');
      this.f.value = this.otherEncryptedInput.value;
      this.f.changed = true;

    } else {
      // the new value failed validation...
      this.renderer.addClass(this.otherEncryptedInput, 'fieldError');
      this.renderer.addClass(this.mainEncryptedInput, 'fieldError');

    }

    return(false);
  }

  onFocusMainInput(): void {

    const mainEncryptedInput = this.elementRef.nativeElement.querySelector('input[name="' + this.f.name + '"]');
    const otherPEncryptedInput = this.elementRef.nativeElement.querySelector('input[name="' + this.f.name + 'Repeat"]');

    // clear the previous formatting, if present...
    this.renderer.removeClass(mainEncryptedInput, 'fieldError');
    this.renderer.removeClass(mainEncryptedInput, 'fieldChanged');
    this.renderer.removeClass(otherPEncryptedInput, 'fieldError');
    this.renderer.removeClass(otherPEncryptedInput, 'fieldChanged');

  }

}
