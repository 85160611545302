import { Injectable, OnDestroy, ComponentFactoryResolver, ViewContainerRef, Type, ComponentRef, ElementRef } from '@angular/core';
import { EventServerService } from './event-server.service';
import { UserAuthService } from './user-auth.service';
import { Subject, Subscription, fromEvent, BehaviorSubject, Observable, of } from 'rxjs';
import { Router, NavigationStart, Params, Event } from '@angular/router';
import { User } from '../data/user.model';
import { WEvent } from '../data/event.model';
import { WResource } from '../data/resource.model';
import { SelectedResource } from '../ui/main/current-selection/selected-resource.model';
import { FieldMode } from '../data/field/field-mode.model';
import { ModalDialogService } from './modal-dialog.service';
import { BusinessRuleService } from './business-rule.service';
import { ScreenType } from './screen-type.enum';
import { catchError, map } from 'rxjs/operators';
import { UtilityLibService } from './utility-lib.service';
import { Globals } from './global.service';
import { WForeignKey, WField, WSelect } from '../data/field.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserInterfaceService implements OnDestroy {

  // menu and display device stuff...

  screenType: BehaviorSubject<ScreenType>;
  screenSizeChange = new Subject<void>();

  private _resizeSubscription: Subscription;

  private _bannerMenuCollapsed = true;
  bannerMenuCollapsed = new Subject<boolean>();

  private _sideNavMenuVisible = false;
  sideNavMenuVisible = new Subject<boolean>();

  private _userAuthSubscription: Subscription;
  private _routerChangeSubscription: Subscription;

  // page stuff...

  currentPage: BehaviorSubject<string>;

  // these are populated from the initial web app load.
  // this allows "non-EH" pages to be passed parameters...
  private _startUpUrlParameters: any = null;

  // This USED to be in Globals, but now we set it dynamically...
  public previousApplication = '';

  pageEvent: Subject<WEvent>;

  // because we couldn't get RepositoryPage and a sub-class of it to work nicely, we pass this from this service.
  public selectResourceOnRepositoryGridClick = true;

  selectionChange: Subject<SelectedResource>;
  private _selectedResources: { [ehName: string]: SelectedResource } = {};

  public buildMenu: Subject<void>;
  public clearMenu: Subject<void>;

  _lockedSelectedResources: SelectedResource [] = [];

  private _pageStates: any = null;

  resourceDetailPageDisplayMode: BehaviorSubject<FieldMode>;

  private _user: User;

  public hidePublicFooterContent: BehaviorSubject<boolean>;

  public showPublicMenu = false;
  public showApplicationChrome = true;

  /////////////////////////////////////////////////////
  // centralized foreignKey displayValue storage
  // Cache of labels for fkey values...
  // { resourceType: [{fkeyValue:id1, fkeyLabel:label1}, {fkeyValue:id2, fkeyLabel:label2}, etc...}]
  //   , resourceType: [{fkeyValue:id1, fkeyLabel:label1}, {fkeyValue:id2, fkeyLabel:label2}, etc...}]
  //   , ... }
  /////////////////////////////////////////////////////
  private _foreignKeyDisplayValues = {};

  constructor(
    private _router: Router,
    private _eventServerService: EventServerService,
    private _userAuthService: UserAuthService,
    private _modalDialogService: ModalDialogService,
    private _businessRuleService: BusinessRuleService,
    private _utils: UtilityLibService,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _utilityLibService: UtilityLibService,
  ) {
    this.screenType = new BehaviorSubject<ScreenType>(this.calculateScreenType());
    this.pageEvent = new Subject<WEvent>();

    this.buildMenu = new Subject<void>();
    this.clearMenu = new Subject<void>();

    this.hidePublicFooterContent = new BehaviorSubject<boolean>(this.screenType.getValue() !== ScreenType.fullscreen);

    this._pageStates = this._utils.getJsonCookie(Globals.pageStateCookieName);

    this._resizeSubscription = fromEvent(window, 'resize').subscribe(
      () => {
        this.resetScreenType();
        this.screenSizeChange.next();
      }
    );

    // we start the user at the root page
    this.currentPage = new BehaviorSubject<string>(null);
    this.bannerMenuCollapsed = new BehaviorSubject<boolean>(this._bannerMenuCollapsed);

    // when the user changes - which ONLY occurs when the app first loads...
    this._userAuthSubscription = this._userAuthService.currentUser.subscribe(
      (user: User) => {
        if (user) {
          this._user = user;
          this._intitializeWebApp(user);
        }
      }
    );

    // This handles the firing of events and loading of pages for loadPage(),
    // manual url changes, and the FWD/BACK buttons on the browser...
    this._routerChangeSubscription = this._router.events.subscribe(
      (e: Event) => {
        this._handleRoutingChange(e);
      }
    );

    this.selectionChange = new Subject<SelectedResource>();

    this.resourceDetailPageDisplayMode = new BehaviorSubject<FieldMode>(FieldMode.read);

    this._foreignKeyDisplayValues = {};
  }

  ngOnDestroy(): void {
    if (this._resizeSubscription) {
      this._resizeSubscription.unsubscribe();
    }
    if (this._userAuthSubscription) {
      this._userAuthSubscription.unsubscribe();
    }
    if (this._routerChangeSubscription) {
      this._routerChangeSubscription.unsubscribe();
    }
  }

  getStartUpUrlParameters(): any {
    return this._startUpUrlParameters;
  }

  clearStartUpUrlParameters(): any {
    this._startUpUrlParameters = null;
  }

  private _intitializeWebApp(user: User): void {

    const debug = false;

    // We load the first screen for the app, per the following...
    // ---------------------------------------
    // 1. the user defaults to home page
    // 2. if logged in, we use the default page for the app and user role
    // 3a. if there was a startup cookie, it indicates the page we go to... (w/possible login...)
    // 3b. else if the URL indicates the page, we go there... (w/possible login...)
    // 4. if the user is a guest, we check the business rules for default page per current selections.
    // 5. we go to the page indicated...
    // ---------------------------------------

    // 1. we define the public default...

    let pageName = 'home';
    let action = null;
    let parms: any = {};

    if (debug) { console.log('UISvc._intitializeWebApp()  1 - platform default - pageName: ' + pageName + ', action: ' + action + ', parms: ', parms); }

    // 2. if NOT public, we grab the defaults for the user's role... (sysadmin - i.e. 'administrator' - ALWAYS goes to AccountManager...)

    if (this._userAuthService.isLoggedIn) {
      // the sysadmin/administrator user ONLY goes to AccountManager...
      if ((this._user.userName === 'administrator') && (Globals.thisApplication !== 'AccountManager')) {
          this.goToAccountManager();
      } else {
        pageName = this._businessRuleService.getPostLoginStartUpPage(this._user.role);
        action = this._eventServerService.getDefaultMethod(pageName);
      }
      if (debug) { console.log('UISvc._intitializeWebApp()  1b - web app defaults - pageName: ' + pageName + ', action: ' + action + ', parms: ', parms); }
    } else {
      const publicHomePage = this._businessRuleService.getPublicStartUpPage();
      if (publicHomePage) {
        pageName = publicHomePage;
      }
      if (debug) { console.log('UISvc._intitializeWebApp()  1c - web app defaults - pageName: ' + pageName + ', action: ' + action + ', parms: ', parms); }
  }

    if (debug) { console.log('UISvc._intitializeWebApp()  1d - web app defaults - pageName: ' + pageName + ', action: ' + action + ', parms: ', parms); }

    // if we don't have an action, we check for one in the BusinessRules...
    if (!action) {
      action = this._businessRuleService.getDefaultAction(pageName);
    }

    // 3a. we check to see if we have an incoming startup cookie...

    const startupCookie = this.getStartupCookie();

    if (debug) { console.log('UISvc._intitializeWebApp()  2 - web app defaults - pageName: ' + pageName + ', action: ' + action + ', parms: ', parms, 'startupCookie: ', startupCookie); }

    if (startupCookie) {

      pageName = startupCookie.eh;
      action = (startupCookie.hasOwnProperty('a') ? startupCookie.a : null);
      parms = startupCookie.p;

      const permissionToPage = this.userHasPermissionToThisPage(pageName, action);

      if (debug) {
        console.log('UISvc._intitializeWebApp()  3a - startup cookie - thisApp: ' + Globals.thisApplication + ', pageName: ' + pageName + ', action: ' + action + ', parms: ', parms, ', permissionToPage: ', permissionToPage);
      }

      // if the user does NOT have permission to the requested pageName...
      if (!permissionToPage) {
        // we re-direct them to the login page
        // console.log('3a. no permission to page. loading login page.');
        this._router.navigate(['login']);
        // But we do NOT delete the startup cookie, so that it will be there
        // after the login forces this Angular app to reload from scratch...
        return;
      }

    } else {  // 3b. we check to see if we have an incoming "single-level" page request from the browser URL...

      let domainAndAppPrefix = '.' + Globals.domain + '/';
      if (Globals.thisApplication !== Globals.rootApplication) {
        domainAndAppPrefix += (Globals.thisApplication + '/');
      }
      const endOfDomainAndApp = window.location.href.indexOf(domainAndAppPrefix) + domainAndAppPrefix.length;
      const hrefParts = window.location.href.substring(endOfDomainAndApp).split('?');
      const urlPath = hrefParts[0];

      // now we process the incoming url parameters, if any...

      const queryString = (hrefParts.length === 2 ? hrefParts[1] : '');
      const httpParams = new HttpParams({ fromString: queryString });

      if (httpParams.keys().length > 0) {

        this._startUpUrlParameters = {};

        for (const name of httpParams.keys()) {
          const value = httpParams.get(name);

          // HACK ALERT! Special Handling for AccountManager previousApplication parm...
          if ((name === 'previousApplication') && (Globals.thisApplication === 'AccountManager')) {
            // console.log('capturing previousApplication: ', name, value);
            this.previousApplication = value;
          } else {

            this._startUpUrlParameters[name] = value;

            if (name === 'page') {
              pageName = value;
            } else if (name === 'action') {
              action = value;
            } else {
              parms[name] = value;
            }
          }
        }
      }

      if (debug) { console.log('UISvc._intitializeWebApp()  3b - do we need to parse the URL? - hrefParts: ', hrefParts, 'urlPath: ' + urlPath, 'this._startUpUrlParameters: ', this._startUpUrlParameters, 'parms: ', parms); }

      if (urlPath !== '') {
        const urlPathParts: string [] = urlPath.split('/');
        if (
              (
                (urlPathParts.length === 1)
                ||
                ((urlPathParts.length === 2) && (urlPathParts[0] === Globals.thisApplication))
              )
              &&
              (
                // this === '' when loading home page on a "sub" web app...
                (urlPathParts[urlPathParts.length - 1] !== '')
              )
        ) {
          pageName = urlPathParts[urlPathParts.length - 1];

          if (pageName === 'Reports') {
            action = 'list';
          }

          const permissionToPage = this.userHasPermissionToThisPage(pageName, action);

          if (debug) {
            console.log('UISvc._intitializeWebApp()  3c - yes, we had to parse the URL - thisApp: ' + Globals.thisApplication + ', pageName: ' + pageName + ', action: ' + action + ', parms: ', parms, 'permissionToPage: ' + permissionToPage);
          }

          // if the user does NOT have permission to the requested pageName...
          if (!permissionToPage) {

            // we set the startup cookie so that it will be there after
            // the login forces this Angular app to reload from scratch...
            this.setStartupCookie(pageName, action, parms);
            // need use the alert() to debug the new startup cookie...
            if (debug) { console.log('UISvc._intitializeWebApp()  3d - no permission to page, so setting startupCookie: ', this.getStartupCookie()); }
            // we re-direct them to the login page
            this._router.navigate(['login']);
            return;
          }
        }

      }
    }

    // if we get here, we can delete the startup cookie (if there was one...)
    this._utils.deleteCookie(Globals.startupCookieName);

    if (debug) { console.log('UISvc._intitializeWebApp()  4 - pre-business rule check for guests - ' + (user.isGuestOnly ? 'IS ' : 'IS NOT') + ' guest - pageName: ' + pageName + ', action: ' + action + ', parms: ', parms); }

    // if we're a guest, we MIGHT not have permission to the "normal" startup page,
    // but we are sure to have SOMEthing configured in the business rules...
    if (user.isGuestOnly && !this._businessRuleService.checkMenuRules(user, this._selectedResources, this._eventServerService.userAPI, pageName)) {
      const tempPageName = this._businessRuleService.getMenuPageNameForCurrentSelections(user, this._selectedResources, this.screenType.getValue());
      if (tempPageName) {
        pageName = tempPageName;
      }
    }

    // remember, guest in AccountManager is a special case...
    if (user.isGuestOnly && (Globals.thisApplication !== 'AccountManager')) {
      this._lockGuestResources(user);
    }

    if (debug) {
      console.log('UISvc._intitializeWebApp()  5 - ' +  Globals.thisApplication + ' trying to load: ' + pageName + ' ' + action, parms);
    }

    this.loadPage(pageName, action, parms);

    // If the user is not logged in, but they are looking at an account-specific home page, they MIGHT have a public menu...
    // Note: If we have a public menu, we probably want it WITHIN the public home page (like GolfTournament does...)
    this.showPublicMenu = this._businessRuleService.hasPublicMenu() && (this._user.accountID !== 1);

    if (!this._userAuthService.isLoggedIn && !this.showPublicMenu) {
      this.clearMenu.next();
    }
  }

  private _handleRoutingChange(e: Event): void {
    // console.log('UISvc._handleRoutingChange()', e);

    const debug = false;

    if (e instanceof NavigationStart) {

      // console.log('UISvc._handleRoutingChange()', JSON.stringify(e.url), JSON.stringify(e.url.substr(1)));

      const pageName = e.url.substr(1);

      const navigationStateInfo = this._router.getCurrentNavigation().extras.state;

      if (debug) {
        console.log('UISvc._handleRoutingChange() - app: ' + Globals.thisApplication
                      , '\nurl: ' + e.url
                      + '\npage: ' + pageName
                      + '\ndefault method: ' + this._eventServerService.getDefaultMethod(pageName)
                      + '\nstate: ', navigationStateInfo
                      , '\nstate method: ' + (navigationStateInfo ? navigationStateInfo.action + ' (' + (this._eventServerService.isValidMethod(pageName, navigationStateInfo.action) ? '' : 'in-') + 'valid)' : null)
                      + '\nis EH? ' + this._eventServerService.isEventHandler(pageName)
                      // + '\n', new Error().stack
                 );
      }

      if (
        this._eventServerService.isEventHandler(pageName)
        &&
        (
          this._eventServerService.getDefaultMethod(pageName)
          ||
          (navigationStateInfo && this._eventServerService.isValidMethod(pageName, navigationStateInfo.action))
        )
      ) {

        let action = this._eventServerService.getDefaultMethod(pageName);

        // if we don't have an action, we check for one in the BusinessRules...
        if (!action) {
          action = this._businessRuleService.getDefaultAction(pageName);
        }

        let parms = {};

        const urlParms: Params = this._router.getCurrentNavigation().extractedUrl.queryParams;

        // console.log('urlParms', urlParms);

        // if the user hits the BACK or FWD button on the browser...
        if (e.navigationTrigger === 'popstate') {
          // then the last "grid state" is read in the "fireEvent()" call below...

        // if we got URL parms...
        } else if (Object.entries(urlParms).length > 0) {

          parms = urlParms;

        // if we are doing a simple load page...
        } else if (navigationStateInfo) {
          // console.log('UISvc._handleRoutingChange() - navigation state: ', navigationStateInfo);
          // we got this stuff from loadPage()
          action = (navigationStateInfo.action ? navigationStateInfo.action : action);
          parms = (navigationStateInfo.parms ? navigationStateInfo.parms : parms);

        // } else if (this._router.routerState.root.params) {
        //   console.log('UISvc._handleRoutingChange() - Need to read action and parms from URL query parameters!', this._router.routerState.root.params);
        }

        // console.log('UISvc._handleRoutingChange() - about to load navigation route for repository!\npage: ' + pageName + '\naction: ' + action + '\nparms:', parms);

        // if we have a bad method, we simply revert to the default method...
        // however, 'display' is an action we mock up to allow EH pages to load w/o any action being fired...
        if (action && !this._eventServerService.isValidMethod(pageName, action) && (action !== 'display')) {
          this._modalDialogService.showAlert('Invalid action "' + action + ' for "' + pageName + '" page...', 'Error');
        } else {

          // we have a good method... let's do some special handling for add & modify...

          if (action === 'add') {

            this.resourceDetailPageDisplayMode.next(FieldMode.write);

            // generate a new, blank resource, and populate it w/the given parms

            // The detail page requires that ALL possible fields be present in the selected
            // resource - even if the fields all have null values - including for add().
            // We cannot use "new Resource()" because that creates a field-less Resource.
            // We need to use Resource.factory(), which assigns null to all the defined
            // fields in the user's API - and ESSvc.newResource() is a convenience
            // function that handles that.

            const newResource = this._eventServerService.newResource(pageName);

            // Note that we want to disable the user's ability to enter a value in the ID field
            // for this new resource, because that will be generated on the server...
            newResource.keyField.readOnly = true;
            // but we need a null value here so we can have something sensible in the currentSelection div...
            newResource.keyField.value = null;
            newResource.keyField.displayValue = 'new, unsaved ' + newResource.getType();

            // select the new, mostly-blank resource
            newResource.setFieldsFromParms(parms);
            this.selectResource(pageName, newResource);

            // set the detail page to edit mode
            this.resourceDetailPageDisplayMode.next(FieldMode.write);

            // console.log('UISvc._handleRoutingChange() - about to ADD!\npage:', pageName + ', parms:', parms, ', newResource:', newResource);

            // now, we just load the page w/o any kind of event...

            // console.log('UISvc._handleRoutingChange() - signalling next page: ' + pageName);

            // notify everybody (i.e. all subscribers) that we're loading a new page...
            this.currentPage.next(pageName);

            window.setTimeout(
              () => {
                // HACKALERT!
                // We need to pass in a fake list/search event to trick/kick the 'add' page into loading
                // the selected resource we just created and selected above. To do that, we need to let
                // the page load first, so we use this timeout mechanism to give up control and put
                // "this" execution BEHIND execution of the subscribers we just notified...
                const fakeEvent = new WEvent();
                fakeEvent.firstHandler = pageName;
                fakeEvent.action = 'list';
                fakeEvent.status = 'OK';
                // console.log('UISvc._handleRoutingChange() - signalling fakeEvent:', fakeEvent);
                this.pageEvent.next(fakeEvent);
              }
              , 0
            );

          } else if (action === 'modify') {

            this.resourceDetailPageDisplayMode.next(FieldMode.write);

            // select the resource with the new values from the UX, and open the page for editing...
            const targetResource = this._eventServerService.newResource(pageName);

            // console.log('UISvc._handleRoutingChange() - about to MODIFY!\npage: ' + pageName + ' action: ' + action + ' parms:', parms, 'targetResource:', targetResource, 'targetResource.keyField.name: ' + targetResource.keyField.name);

            // if we, in fact, HAVE the key field for this item in the given parms...
            if (parms[targetResource.keyField.name]) {
              const keyAsParm = {};
              keyAsParm[targetResource.keyField.name] = parms[targetResource.keyField.name];

              this._eventServerService.loadResourceFromServer(pageName, keyAsParm).subscribe(
                (resource: WResource) => {
                  try {
                    resource.setFieldsFromParms(parms);
                    // console.log('selecting resource', resource);
                    this.selectResource(pageName, resource);
                    this.resourceDetailPageDisplayMode.next(FieldMode.write);

                    // null action means fire the default event (search or list...)
                    if (debug) {
                      console.log('UISvc._handleRoutingChange() - about to fire NULL page event on resource for a MODIFY: ', pageName, 'null', parms);
                    }
                    this._firePageEvent(pageName, null, keyAsParm);

                  } catch (ex) {
                    console.log(ex);
                  }
                }
              );
            } else {
              this._modalDialogService.showAlert('Missing key parameter value "' + targetResource.keyField.name + '"', 'Error');
            }
          // 'display' is an action we mock up to allow EH pages to load w/o any action being fired...
          } else if (action === 'display') {
            if (debug) {
              console.log('simple page load: ' + pageName, 'state: ', navigationStateInfo);
            }
            this.currentPage.next(pageName);
          } else {

            this.resourceDetailPageDisplayMode.next(FieldMode.read);

            // Note that pageName = eventHandler...
            if (debug) {
              console.log('UISvc._handleRoutingChange() - about to fire page event: ', pageName, action, parms);
            }

            this._firePageEvent(pageName, action, parms);
          }
        }

      } else { // simply load the page...

        if (debug) {
          console.log('simple page load: ' + pageName, 'state: ', navigationStateInfo);
        }
        this.currentPage.next(pageName);

      }

    }
  }

  public userHasPermissionToThisPage(pageName: string, action: string): boolean {
    let flag = false;

    // console.log('userHasPermissionToThisPage()', pageName, action, this._businessRuleService.isPublicPage(pageName),
    //             this._eventServerService.isEventHandler(pageName), this._eventServerService.getMethods(pageName),
    //             this._businessRuleService.businessRules.otherMenuItems.includes(pageName));

    // If a public user is accessing a public page (like login, help, PriceList, etc...)
    if (!pageName || (pageName === '') || (this._user.isPublicOnly && this._businessRuleService.isPublicPage(pageName))) {

      flag = true;

    // If it's an eventHandler, then it doesn't matter if the user is logged in or not...
    // (It would not be in the user's API if they did NOT have permission to it...)
    } else if (this._eventServerService.isEventHandler(pageName)) {

      if (action === null) {
        action = this._eventServerService.getDefaultMethod(pageName);
      }

      flag = this._eventServerService.getMethods(pageName).includes(action);

    // If the user is logged in, and it's a non-EH page for this web app, then it's a valid startup page...
    } else if (this._userAuthService.isLoggedIn && this._businessRuleService.businessRules.otherMenuItems.includes(pageName)) {

      flag = true;
    }

    // console.log('userHasPermissionToThisPage()', pageName, action, this._businessRuleService.isPublicPage(pageName),
    //             this._eventServerService.isEventHandler(pageName), this._eventServerService.getMethods(pageName),
    //             this._businessRuleService.businessRules.otherMenuItems.includes(pageName), flag);

    return flag;
  }

  private setStartupCookie(eventHandler: string, action: string, parms: any): void {

    const startupCookie: {eh: string, a: string, p: any} = { eh : eventHandler, a : action, p : parms };

    // console.log('setStartupCookie() - startupCookie', startupCookie);

    const startupCookieJSONString = JSON.stringify(startupCookie);

    // console.log('setStartupCookie() startupCookieJSONString: ' + startupCookieJSONString);

    // we CANNOT use btoa() because it does NOT support UTF-8 properly...
    // const startupCookieValueBase64String = btoa(startupCookieJSONString);
    const startupCookieValueBase64String = this._utilityLibService.btoaForUnicodeString(startupCookieJSONString);

    // console.log('setStartupCookie() - startupCookieValueBase64String: ' + startupCookieValueBase64String);

    // now set the startup cookie...
    this._utils.setCookie(Globals.startupCookieName, startupCookieValueBase64String);

  }

  private getStartupCookie(): {eh: string, a: string, p: any} {

    let startupCookie: {eh: string, a: string, p: any} = null;

    // Unless, of course, we have received a startup cookie...
    let startupCookieValueBase64String = this._utils.getCookie(Globals.startupCookieName);

    // console.log('getStartupCookie() - startupCookieValueBase64String: ' + startupCookieValueBase64String);

    if (startupCookieValueBase64String !== null) {
      // parse out page and parms (and action) from the startup cookie...

      // gotta scrub the cookie for the leading/trailing " that encapsulate the whole thing...
      if (startupCookieValueBase64String.startsWith('"') && startupCookieValueBase64String.endsWith('"')) {
        startupCookieValueBase64String = startupCookieValueBase64String.substring(1, startupCookieValueBase64String.length - 1);
      }

      // console.log('getStartupCookie() - startupCookieValueBase64String: ' + startupCookieValueBase64String);

      // we CANNOT use atob() because it does NOT support UTF-8 properly...
      // const startupCookieJSONString = atob(startupCookieValueBase64String);
      const startupCookieJSONString = this._utils.atobForUnicodeContent(startupCookieValueBase64String);

      // console.log('getStartupCookie() startupCookieJSONString: ' + startupCookieJSONString);

      startupCookie = JSON.parse(startupCookieJSONString);

      // console.log('getStartupCookie() - startupCookie', startupCookie);

    }

    return(startupCookie);
  }

  // a bunch of to/from app navigation methods...

  goToApp(appName: string): void {
    if (Globals.rootApplication === appName) {
      window.location.href = '/' + (appName === 'AccountManager' ? '?previousApplication=' + Globals.thisApplication : '');
    } else {
      window.location.href = '/' + appName + (appName === 'AccountManager' ? '?previousApplication=' + Globals.thisApplication : '');
    }
  }

  getApplicationUrl(): string {
    const isRootApp = (Globals.rootApplication === Globals.thisApplication);
    return window.location.origin + (isRootApp ? '' : '/' + Globals.thisApplication);
  }

  goToAccountManager(): void {
    if (Globals.rootApplication === 'AccountManager') {
      window.location.href = '/?previousApplication=' + Globals.thisApplication;
    } else {
      window.location.href = '/AccountManager?previousApplication=' + Globals.thisApplication;
    }
  }

  returnToPreviousApp(): void {
    if ((Globals.rootApplication === this.previousApplication) || (Globals.rootApplication === Globals.notAccountManager)) {
      window.location.href = '/';
    } else {
      window.location.href = '/' + this.previousApplication + '';
    }
  }

  goToRootApp(): void {
    if (Globals.rootApplication === Globals.thisApplication) {
      this.loadPage('home');
    } else {
      window.location.href = '/' + (Globals.rootApplication === 'AccountManager' ? '?previousApplication=' + Globals.thisApplication : '');
    }
  }

  goToPricing(): void {
    if (Globals.rootApplication === 'AccountManager') {
        window.location.href = '/Pricing';
    } else {
      // Remember, Pricing info for non-AccountManager root level apps is on the NewCustomer detail page...
      // so this is the same as the sign up button...
      window.location.href = '/AccountManager/NewCustomer?action=add&previousApplication=' + Globals.thisApplication;
    }
  }

  // This is used for the user's account logo in the banner, and (possibly) elsewhere...

  get accountLogoURL(): string {
    let accountLogoURL = this._accountManagerURLPrefix + '/EventServer/Accounts/getFile';
    accountLogoURL += '?accountID=' + this._user.accountID;
    accountLogoURL += '&binaryContentFieldName=logoFileContent';
    // accountLogoURL += '&' + this._eventServerService.getSessionTokenURLParameter();

    return accountLogoURL;
  }

  private get _accountManagerURLPrefix(): string {

    const appName  = (Globals.thisApplication ? Globals.thisApplication : Globals.rootApplication);

    // console.log('accountManagerURLPrefix()', window.location.href, 'Globals.rootApplication: ' + Globals.rootApplication, 'appName: ' + appName);

    let accountManagerURLPrefix = '';

    if (Globals.rootApplication === 'AccountManager') {
      if (appName === 'AccountManager') {
        accountManagerURLPrefix = '.';
      } else {
        accountManagerURLPrefix = '..';
      }
    } else if (Globals.rootApplication !== 'AccountManager') {
      accountManagerURLPrefix = '/AccountManager';
    }

    return(accountManagerURLPrefix);
  }

  overRideAngularRouterPathComponent(path: string, component: any): void {
    const config = this._router.config.find((x: any) => x.path === path);
    if (config) {
      config.component = component;
    } else {
      console.log('UISvc.overRideAngularRouterPathComponent()\nWARNING: Unable to find Router config for path: ' + '"' + path + '"\n', component);
    }
  }

  get relativeEventServerURL(): string {
    return((Globals.thisApplication === Globals.rootApplication ? '' : '/' + Globals.thisApplication ) + '/EventServer');
  }

  resetScreenType(): void {
    this.screenType.next(this.calculateScreenType());
  }

  public isSmallFullScreen(): boolean {
    const w = window.outerWidth;
    const smallFullScreenBreakPoint = 1536; // the second largest popular full screen size (per quick web search...)
    return w <= smallFullScreenBreakPoint;
  }

  private calculateScreenType(): ScreenType {

    let screenType = ScreenType.fullscreen;

    const w = window.outerWidth;

    // console.log('calculateScreenType()', h, w, new Error().stack);

    const phoneBreakPoint = 767.9;  // 768 is the smallest tablet dimension...
    // const tabletBreakPoint = 1024.1;  // 1024 is the largest tablet width... (we customized Bootstrap to this in variables.scss)
    const tabletBreakPoint = 1180.1;  // 1180 is the width of an iPad Air... (we customized Bootstrap to this in variables.scss)

    // console.log('UISvc.calculateScreenType()', w, phoneBreakPoint, tabletBreakPoint);

    // We check width to determine what we're running on... (height appears to be irrelevant...)
    // (This is because the CSS rules for Bootstrap toggle the Banner menu based
    // on width only, and we need to trigger our Nav menu at the same time...)

    if (w < phoneBreakPoint) {
      screenType = ScreenType.phone;
    } else if (w < tabletBreakPoint) {
      screenType = ScreenType.tablet;
    }

    return screenType;
  }

  toggleBannerMenu(): void {
    // console.log('UIService - toggling...');
    this._bannerMenuCollapsed = !this._bannerMenuCollapsed;
    this.bannerMenuCollapsed.next(this._bannerMenuCollapsed);
  }

  showBannerMenu(): void {
    // console.log('UIService - showing...');
    this._bannerMenuCollapsed = false;
    this.bannerMenuCollapsed.next(this._bannerMenuCollapsed);
  }

  hideBannerMenu(): void {
    this._bannerMenuCollapsed = true;
    this.bannerMenuCollapsed.next(this._bannerMenuCollapsed);
  }

  toggleSideNavMenu(): void {
    // console.log('UISvc.toggleSideNavMenu()');
    this._sideNavMenuVisible = !this._sideNavMenuVisible;
    this.sideNavMenuVisible.next(this._sideNavMenuVisible);
  }

  showSideNavMenu(): void {
    // console.log('UISvc.showSideNavMenu()');
    this._sideNavMenuVisible = true;
    this.sideNavMenuVisible.next(this._sideNavMenuVisible);
  }

  hideSideNavMenu(): void {
    // console.log('UISvc.hideSideNavMenu()');
    this._sideNavMenuVisible = false;
    this.sideNavMenuVisible.next(this._sideNavMenuVisible);
  }

  loadComponent(viewContainerRef: ViewContainerRef, componentClassName: string, inputFieldAsParms?: any, failSilently?: boolean, defaultComponentClassName?: string): ComponentRef<any> {
    inputFieldAsParms = typeof inputFieldAsParms !== 'undefined' ? inputFieldAsParms : {};
    failSilently = typeof failSilently !== 'undefined' ? failSilently : false;
    defaultComponentClassName = typeof defaultComponentClassName !== 'undefined' ? defaultComponentClassName : null;

    // console.log('_loadComponent() - entering', 'componentClassName: ' + componentClassName, 'defaultComponentClassName: ' + defaultComponentClassName);
    // console.log('_loadComponent() - entering', viewContainerRef, componentClassName, defaultComponentClassName, inputFieldAsParms);

    try {

      // dynamically instantiate the page-specific "componentClassName" using the Angular factory API

      // per https://stackoverflow.com/questions/40115072/how-to-load-component-dynamically-using-component-name-in-angular2
      // tslint:disable-next-line: no-string-literal
      const factories = Array.from(this._componentFactoryResolver['_factories'].keys());

      // console.log('_loadComponent()', 'factories', factories);

      let factoryClass: Type<any>;

      if (componentClassName && (componentClassName !== '')) {
        // old way! via name...  (works w/regular builds, but not AOT builds because AOT renames a lot of classes the same thing...)
        // factoryClass = factories.find((x: any) => x.name === componentClassName) as Type<any>;

        // new way! via static componentNameUsedForDynamicContentInAOT defined in each loadable class... (works w/regular and AOT builds)
        factoryClass = factories.find((x: any) => x.componentNameUsedForDynamicContentInAOT === componentClassName) as Type<any>;

        if ((failSilently === false) && (typeof factoryClass === 'undefined')) {
          // console.log('_loadComponent()', 'factoryClass (' + componentClassName + ')', failSilently, factoryClass, new Error().stack);
          this._modalDialogService.showAlert('The componentClassName "' + componentClassName + '" is either not defined as an entryComponent in the web app module file, or the static componentNameUsedForDynamicContentInAOT is missing!', 'Web App Config Error!');
        }
      }

      // if we don't have a componentClassName, grab the default one...
      if (defaultComponentClassName && (typeof factoryClass === 'undefined')) {
        // old way! via name...  (works w/regular builds, but not AOT builds because AOT renames a lot of classes the same thing...)
        // factoryClass = factories.find((x: any) => x.name === defaultComponentClassName) as Type<any>;

        // new way! via static componentNameUsedForDynamicContentInAOT defined in each loadable class... (works w/regular and AOT builds)
        factoryClass = factories.find((x: any) => x.componentNameUsedForDynamicContentInAOT === defaultComponentClassName) as Type<any>;

        // console.log('_loadComponent()', 'factoryClass default (' + defaultComponentClassName + ')', factoryClass);
        if ((failSilently === false) && (typeof factoryClass === 'undefined')) {
          // console.log('_loadComponent()', 'default factoryClass (' + defaultComponentClassName + ')', failSilently, factoryClass, new Error().stack);
          this._modalDialogService.showAlert('The defaultComponentClassName "' + defaultComponentClassName + '" is either not defined as an entryComponent in the web app module file, or the static componentNameUsedForDynamicContentInAOT is missing!', 'Web App Config Error!');
        }
      }

      // console.log('_loadComponent()', 'factoryClass', factoryClass);

      let componentRef = null;

      if (factoryClass) {
        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(factoryClass);

        // console.log('_loadComponent()', 'componentFactory', componentFactory);

        // empty out the old contents of the viewContainerRef
        viewContainerRef.clear();

        // and put in the new component...
        componentRef = viewContainerRef.createComponent(componentFactory);

        // console.log('_loadComponent()', 'componentRef', componentRef);

        // now that we have the component, we load in the input values we want...
        if (inputFieldAsParms) {
          for (const [name, value] of Object.entries(inputFieldAsParms)) {
            componentRef.instance[name] = value;
          }
        }

      }

      // return this thing for cleanup during ngOnDestroy() of the calling component...
      return componentRef;

    } catch (ex) {
      console.log(ex);
    }

    // console.log('_loadComponent() - exiting', 'componentClassName: ' + componentClassName, 'defaultComponentClassName: ' + defaultComponentClassName, viewContainerRef);

  }

  loadPage(pageName: string, action?: string, parms?: any): void {
    // console.log('UISvc.loadPage() - Got here! ' + pageName + ', action: ' + action +  ', parms:', parms);

    const route = pageName;

    const temp: any = {};
    temp.action = action;
    temp.parms = parms;
    const navStateInfo = { state: temp };

    // console.log('UISvc.loadPage() - Got there! ' + pageName +  ', parms:', parms, 'temp: ' + JSON.stringify(temp), temp, 'navStateInfo: ' + JSON.stringify(navStateInfo), navStateInfo, new Error('debug'));

    this._router.navigate([route], navStateInfo);
  }

  reloadCurrentPage(): void {
    const route = this.currentPage.getValue();
    // HACK ALERT!!!
    // Since the forRoot() onSameUrlNavigation 'reload' does NOT appear to reload components...
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.navigated = false;
    this._router.navigate([route]);
  }

  private _firePageEvent(eventHandler: string, action?: string, parms?: any): void {
    action = ((typeof action === 'string') && (action !== 'null')) ? action : this._eventServerService.getDefaultMethod(eventHandler);
    parms = typeof parms !== 'undefined' ? parms : {};

    // console.log('UISvc._firePageEvent() - Got here! ' + eventHandler + '.' + action + '() with parms:', parms);

    // if no parameters, we did a bare page load... (possibly from the menu, possibly not...)
    // then we look at a couple special cases: ResourceRepositories and Reports
    if (Object.entries(parms).length === 0) {
      // if resource repository
      if (this._eventServerService.isResourceRepositoryEventHandler(eventHandler)) {
        // then get the last known grid state...
        parms = this.applyGridState(parms, eventHandler);
      // else if report
      } else if (eventHandler === 'Reports') {
        // then get the parms from the selected report (if any...)
        if (this.getSelectedResource(eventHandler) !== null) {
          parms = this.getSelectedResource(eventHandler).asParms;
        }
      }
    }

    // console.log('UISvc._firePageEvent() - about to apply current selections! ' + eventHandler + '.' + action + '() with parms:', JSON.stringify(parms), parms);

    parms = this.applyCurrentSelectionsToParms(parms, eventHandler);

    // console.log('UISvc._firePageEvent() - about to fire ' + eventHandler + '.' + action + '()', parms, new Date().toJSON());

    // If I understand the nature of the HttpClient operation buried
    // within EventServerService, then this is the return Observable
    // of an http.post() operation, whose Subscription we do NOT need
    // to un-subscribe.

    // CHECK THIS: I took OFF the fireEvent application arg since all EVENTS
    // are always fired against the current application. (OR SO I THINK!!!!)

    this._modalDialogService.showPleaseWait(true, true);

    this._eventServerService.fireEvent(eventHandler, action, parms).subscribe(
      (event: WEvent) => {
        // console.log('=====================================');
        // console.log('UISvc._firePageEvent() - Got event!', new Date().toJSON(), event);

        this._modalDialogService.showPleaseWait(false);

        if (event.status === 'OK') {

          // Good Event...

          // console.log('UISvc._firePageEvent() - loading page: ' + eventHandler);
          this.currentPage.next(eventHandler);

          // console.log('UISvc._firePageEvent() - signalling page event: ' + eventHandler, event, new Date().toJSON());
          this.pageEvent.next(event);

        } else {

          // Bad Event...

          console.log('UISvc._firePageEvent() - bad page event: ', event);

          // we signal a failed page event, and...
          this.pageEvent.next(null);

          // ... and we show an error modal.
          this._modalDialogService.showError(event.message, 'Event Error Occurred', event.status).subscribe(
            () => {
              this.reloadCurrentPage();
            }
          );
        }
      }
    );
  }

  /*
    This decorates the given parameters with all the KEY or FKEY values from the current selections,
    including the selected resource for the named EH.
    (This is what allows us to selectively filter the grids, etc...)
  */
  applyCurrentSelectionsToParms(parms: any, ehName: string): any {
    parms = (typeof parms !== 'undefined') && (parms !== null) ? parms : {};
    ehName = typeof ehName !== 'undefined' ? ehName : this.currentPage.getValue();

    // console.log('UI.setFKeyParmsFromCurrentSelections() - (enter) ' + ehName, parms, this._selectedResources);

    try {
      if (ehName) {

        if (this._eventServerService.getResourceDefinition(ehName)) {
          const foreignKeyFields = this._eventServerService.getResourceForeignKeys(ehName);

          if (foreignKeyFields) {
            for (const srEHName in this._selectedResources) {
              if (this._selectedResources[srEHName]) {
                const selRes = this._selectedResources[srEHName];
                const resourceForeignType = this._eventServerService.getResourceTypeForEventHandler(selRes.ehName);
                if (resourceForeignType) {
                  if (foreignKeyFields.hasOwnProperty(resourceForeignType)) {
                    const localFieldName = foreignKeyFields[resourceForeignType];
                    if (localFieldName) {
                      const foreignResource = this._selectedResources[selRes.ehName].resource;
                      if (foreignResource) {
                        // console.log('UI.setFKeyParmsFromCurrentSelections() - ' + ehName, parms, foreignResource, localFieldName, foreignResource.keyField);
                        parms[localFieldName] = foreignResource.keyField.value;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } catch (ex) {
      const msg = 'setFKeyParmsFromCurrentSelections()';
      this.alertUserToException(ex, msg);
    }

    // console.log('UI.setFKeyParmsFromCurrentSelections() - (exit) ' + ehName, parms);
    return(parms);
  }

/***********************************************************
 * Not sure these belong in UIService, but we do NOT have
 * access to authSvc in eventServerSvc.
 **********************************************************/

  logMessage(message: string, level?: string): void {
    if (this._userAuthService.isLoggedIn) {
      this._eventServerService.logMessage(message, level);
    }
  }

  alertUserToException(ex: Error, message: string, logToConsole?: boolean): void {
    message = typeof message !== 'undefined' ? ('\n\n' + message + '\n') : '';
    logToConsole = typeof logToConsole !== 'undefined' ? logToConsole : false;

    const title = 'Warning';

    let content = 'Time: ' + (new Date()).toUTCString() + message;
    content += ex;

    let stack = (ex instanceof Error ? ex.stack : (new Error()).stack);
    if (!stack) {
      stack = '(no stack trace)';
    }

    if (this._eventServerService) {
      let logMessage = content + '\n\n';
      logMessage += stack;

      this.logMessage(logMessage);
    }

    if (logToConsole) {
      console.log(content + ':\n' + stack + '\n');
    }
    this._modalDialogService.showAlert(content, title);
  }

/***********************************************************
 * Now let's do some resource selection stuff...
 **********************************************************/

  selectResource(ehName: string, resource: WResource): SelectedResource {
    // console.log('UISvc.selectResource() - ' + ehName + ' - ' + resource.keyField.displayValue);
    const sr = new SelectedResource(ehName, resource);
    this._selectedResources[ehName] = sr;

    // console.log('UISvc.selectResource() - selected: ' + ehName, this._selectedResources);

    // when we change selections, we also re-build the menu...
    this.selectionChange.next(sr);
    this.buildMenu.next();

    return sr;
  }

  /**
   * This returns the name of the "first" menu item / EH name given the current selected resources....
   */
  getMenuPageNameForCurrentSelections(): string {
    const pageName = this._businessRuleService.getFirstEHPage(this._user.role);
    const menuPage = this._businessRuleService.getMenuPageNameForCurrentSelections(this._user, this._selectedResources, this.screenType.getValue());
    return menuPage ? menuPage : pageName;
  }

  unSelectResource(unSelectedEHName: string, triggerNavigation?: boolean): void {
    triggerNavigation = typeof triggerNavigation !== 'undefined' ? triggerNavigation : false;

    // console.log('UISvc.unSelectResource() - ' + unSelectedEHName, 'triggerNavigation: ' + triggerNavigation);

    // if this is NOT a "locked" selection...
    if (!this._isSelectedResourceLocked(unSelectedEHName)) {
      // clear the selection that was just clicked on...
      delete this._selectedResources[unSelectedEHName];

      // console.log('UISvc.unSelectResource() - un-selected: ' + unSelectedEHName, this._selectedResources);

      const sr = new SelectedResource(unSelectedEHName, null, this._eventServerService.getKeyFieldName(unSelectedEHName));

      // when we change selections, we also re-build the menu...
      this.selectionChange.next(sr);
      this.buildMenu.next();

      if (triggerNavigation) {

        const ehNameFromBusinessRules = this.getMenuPageNameForCurrentSelections();

        // console.log('UISvc.unSelectResource() - business rule page: ', ehNameFromBusinessRules, 'current page: ' + this.currentPage.getValue(), 'unselected page: ' + unSelectedEHName);

        // If the current page is still shown by the current menu rules, or
        // the current "CustomTemplates" sub-menu, then refresh current page...
        if (
            (unSelectedEHName !== this.currentPage.getValue())
            &&
            this._businessRuleService.checkMenuRules(this._user, this._selectedResources, this._eventServerService.userAPI, this.currentPage.getValue())
        ) {
          // console.log('UISvc.unSelectResource() - loading currentPage: ' + this.currentPage.getValue());
          this.loadPage(this.currentPage.getValue());

        // else if the un-selected "current" page is still shown by the current menu rules, or
        // the current "CustomTemplates" sub-menu, then refresh the un-selected page...
        } else if (
            (unSelectedEHName === this.currentPage.getValue())
            &&
            this._businessRuleService.checkMenuRules(this._user, this._selectedResources, this._eventServerService.userAPI, unSelectedEHName)
        ) {
          // console.log('UISvc.unSelectResource() - loading unSelectedEHName: ' + unSelectedEHName);
          this.loadPage(unSelectedEHName);
        // else if the current selections indicate that SOMEthing would be displayed, go show THAT...
        } else if (ehNameFromBusinessRules) {
          // console.log('UISvc.unSelectResource() - loading ehNameFromBusinessRules: ' + ehNameFromBusinessRules);
          this.loadPage(ehNameFromBusinessRules);
        // else refresh the grid for the just-unselected eventHandler...
        } else {
          // console.log('UISvc.unSelectResource() - clearing all current selections and loading unSelectedEHName: ' + unSelectedEHName);
          this._selectedResources = {};
          this.loadPage(unSelectedEHName);
        }
      }
    } else {
      // it's locked, so DO NOTHING!
      // console.log('UISvc.unSelectResource() - loading LOCKED unSelectedEHName: ' + unSelectedEHName);
    }

  }

  getSelectedResource(ehName: string): WResource {
    return this._selectedResources[ehName] ? this._selectedResources[ehName].resource : null;
  }

  clearSelectedResources(): void {
    this._selectedResources = {};
  }

  getSelectedResourceByResourceType(resourceType: string): WResource {
    let r: WResource = null;
    for (const sr of Object.values(this._selectedResources)) {
      // console.log(resourceType, eh, sr, sr.resource.getType());
      if (sr.resource && sr.resource.getType() && (sr.resource.getType().toLowerCase() === resourceType.toLowerCase())) {
        r = sr.resource;
        break;
      }
    }
    return r;
  }

  reloadSelectedResourceFromServer(ehName: string): void {
    const selectedResource = this.getSelectedResource(ehName);
    if (selectedResource) {
      // when there's an fkey value in the key's displayValue, it's not properly set in the new resource...
      // so we save the "current" displayValue to put back in later...
      const displayValue = selectedResource.keyField.displayValue;
      this._eventServerService.loadResourceFromServer(ehName, selectedResource.keyField.asParm).subscribe(
        (resource: WResource) => {
          resource.keyField.displayValue = displayValue;
          this.selectResource(ehName, resource);
        }
      );

    }
  }

  get currentSelections(): { [ehName: string]: SelectedResource } {
    // when this was an array, we used slice() to give that component a COPY, and NOT the original...
    return this._selectedResources;
  }

  /****************************************************************************
 * these methods provide a non-cookie method of providing cross-instance
 * page state info that allows a page to "return" to it's previous state
 ***************************************************************************/

  setPageState(page: string, state?: string, value?: any): void {
    if (page && state) {
      if (!this._pageStates) {
        this._pageStates = this._utils.getJsonCookie(Globals.pageStateCookieName);
      }
      if (!this._pageStates.hasOwnProperty(page)) {
        this._pageStates[page] = {};
      }
      if (value) {
        this._pageStates[page][state] = value;
      } else {
        delete this._pageStates[page][state];
      }
      this._utils.setJsonCookie(Globals.pageStateCookieName, this._pageStates);
    }
  }

  getPageState(page: string, state: string): any {
    if (page && state && this._pageStates && this._pageStates[page]) {
      return(this._pageStates[page][state]);
    }
    return(null);
  }

  dumpPageState(): void {
    console.log('UISvc.dumpPageState()', this._pageStates);
  }

  clearPageState(page: string, state?: string): void {
    if (page && state && this._pageStates && this._pageStates[page]) {
      delete this._pageStates[page][state];
    } else if (page && this._pageStates) {
      delete this._pageStates[page];
    }
  }

  // These two are targeted specifically at repository grid pages...

  setGridState(pageName: string, parms: any): void {
    if (parms) {
      this.setPageState(pageName, 'startAt', parms.startAt);
      this.setPageState(pageName, 'pageSize', parms.pageSize);
      this.setPageState(pageName, 'sortBy', parms.sortBy);
      this.setPageState(pageName, 'sortDirection', parms.sortDirection);
      this.setPageState(pageName, 'query', parms.query);
    }
  }

  applyGridState(originalParms: any, pageName: string): any {

    if (originalParms) {

      if (!originalParms.startAt && this.getPageState(pageName, 'startAt')) {
        originalParms.startAt = this.getPageState(pageName, 'startAt');
      }

      if (!originalParms.pageSize && this.getPageState(pageName, 'pageSize')) {
        originalParms.pageSize = this.getPageState(pageName, 'pageSize');
      }

      if (!originalParms.sortBy && this.getPageState(pageName, 'sortBy')) {
        originalParms.sortBy = this.getPageState(pageName, 'sortBy');
      }

      if (!originalParms.sortDirection && this.getPageState(pageName, 'sortDirection')) {
        originalParms.sortDirection = this.getPageState(pageName, 'sortDirection');
      }

      if (!originalParms.query && this.getPageState(pageName, 'query')) {
        originalParms.query = this.getPageState(pageName, 'query');
      }

      // console.log('applyPageGridState('+pageName + ') - ' + JSON.stringify(originalParms));
    }

    return(originalParms);
  }

  //////////////////////////////////
  // Guest resource lock stuff
  //////////////////////////////////

  public lockResourceSelection(eh: string, resource: WResource): void {
    const sr = this.selectResource(eh, resource);
    this._lockedSelectedResources.push(sr);
  }

  private _isSelectedResourceLocked(eh: string): boolean {
    return (this._lockedSelectedResources.findIndex( (sr) => sr.ehName === eh) > -1);
  }

  private _lockGuestResources(user: User): void {

    // console.log('_lockGuestResources()', user);

    if (user.guestID && user.guestType && user.guest) {

      const guestEhName = this._eventServerService.getEventHandlerForResourceType(user.guestType);
      const guestResource = user.guest;

      // console.log('_lockGuestResources() - just loaded guest', user, guestEhName, parms, guestResource);

      this.lockResourceSelection(guestEhName, guestResource);
      this._lockForeignKeyResources(guestEhName, guestResource);

    } else {
      console.log('Bad guest', user);
      throw new Error('Missing required guest! guestID (' + user.guestID + ') or guestType (' + user.guestType + ')');
    }

    if (user.groupID && user.groupType && user.group) {
      const groupEhName = this._eventServerService.getEventHandlerForResourceType(user.groupType);
      const guestGroupResource = user.group;

      // console.log('_lockGuestResources() - just loaded guest group', user, groupEhName, parms, guestGroupResource);

      this.lockResourceSelection(groupEhName, guestGroupResource);
      this._lockForeignKeyResources(groupEhName, guestGroupResource);

      // OK, now that we've loaded this, we can load the page...

    } else {
      console.log('Bad group', user);
      throw new Error('Missing required group! groupID (' + user.groupID + ') or groupType (' + user.groupType + ')');
    }
  }

  // this locks in any/all resources found in the non-null fkeys for this resource...

  private _lockForeignKeyResources(ehName: string, resource: WResource): void {
    // now walk all the foreign keys in the resource...
    const foreignKeyFields = this._eventServerService.getResourceForeignKeys(ehName);
    if (foreignKeyFields) {
      // console.log('lockForeignKeyResources() - '+JSON.stringify(foreignKeyFields));
      for (const [foreignType, foreignKeyFieldName] of Object.entries(foreignKeyFields) ) {
        const foreignEHName = this._eventServerService.getEventHandlerForResourceType(foreignType);
        // if this user has permission to see this item...
        if (this._eventServerService.isEventHandler(foreignEHName)) {
          // console.log('lockForeignKeyResources() - foreignType: ' + foreignType);
          const foreignKeyField = resource.getField(foreignKeyFieldName);
          if (foreignKeyField.value) {
            this._eventServerService.loadResourceFromServer(foreignEHName, foreignKeyField.asParm).subscribe(
              (res: WResource) => {
                this.lockResourceSelection(foreignEHName, res);
              }
            );
          }
        }
      }
    }
  }

  /////////////////////////////////////////////////////////////
  // centralized foreignKey displayValue storage utilities
  //
  // { resourceType: [{fkeyValue:id1, fkeyLabel:label1}, {fkeyValue:id2, fkeyLabel:label2}, etc...}]
  //   , resourceType: [{fkeyValue:id1, fkeyLabel:label1}, {fkeyValue:id2, fkeyLabel:label2}, etc...}]
  //   , ... }
  /////////////////////////////////////////////////////////////

  getForeignKeyDisplayValues(foreignResourceType: string): object [] {
    return (this._foreignKeyDisplayValues[foreignResourceType] ? this._foreignKeyDisplayValues[foreignResourceType] : null);
  }

  setForeignKeyDisplayValue(foreignResourceType: string, fkeyValue: any, fkeyLabel: string): void {
    if (!this._foreignKeyDisplayValues[foreignResourceType]) {
      this._foreignKeyDisplayValues[foreignResourceType] = [];
    }
    const existing = this._foreignKeyDisplayValues[foreignResourceType].find((x: any) => x.fkeyValue === fkeyValue);
    if (existing) {
      existing.fkeyLabel = fkeyLabel;
    } else {
      this._foreignKeyDisplayValues[foreignResourceType].push({fkeyValue, fkeyLabel});
    }
  }

  getForeignKeyDisplayValue(foreignResourceType: string, fkeyValue: any): string {
    const y = (this._foreignKeyDisplayValues[foreignResourceType] ? this._foreignKeyDisplayValues[foreignResourceType].find((x: any) => x.fkeyValue === fkeyValue) : null);
    if (y) {
      return y.fkeyLabel;
    }
    return null;
  }

  private _clearForeignKeyDisplayValues(foreignResourceType?: string): void {
    if (foreignResourceType) {
      if (this._foreignKeyDisplayValues[foreignResourceType]) {
        delete this._foreignKeyDisplayValues[foreignResourceType];
        // console.log('cleared ' + foreignResourceType, this._foreignKeyDisplayValues);
      } else {
        // console.log('did NOT clear ' + foreignResourceType, this._foreignKeyDisplayValues);
      }
    } else {
      this._foreignKeyDisplayValues = {};
      console.log('cleared ALL', this._foreignKeyDisplayValues);
    }
  }

  // If we want only SOME ids, then pass in a non-empty array of ids in idArray. Otherwise,
  // we will get ALL valid possible values for the given foreignType.
  // Then, if you want to filter THAT list of returned IDs to be only for ones that match on some
  // field value in the foreign resource, use lookupFilterParms, which will be added to the lookup request...

  loadForeignKeyDisplayValuesFromTheServer(foreignType: string, idArray?: any[], lookupFilterParms?: any): Observable<string> {

    let errorMessage = null;

    // first, we clear out ALL the old FKey display values for this type...
    this._clearForeignKeyDisplayValues(foreignType);

    const foreignKeyEventHandlerName = this._eventServerService.getEventHandlerForResourceType(foreignType);

    // console.log('loadForeignKeyDisplayValuesFromTheServer() - lookupFilterParms:', lookupFilterParms);

    //  This loads lookup strings for all non-String foreignKey values for the indicated foreignKeyResourceType.
    //  If you want to filter the list of returned IDs on some field value in the foreign type, use
    //  lookupFilterField and lookupFilterValue
    // const debug = false;
    // const debug = (foreignKeyEventHandlerName == 'CreditCards');
    // const debug = (foreignKeyEventHandlerName == 'Users');
    // const debug = (foreignType === 'TaskTemplateGroup');

    // if (debug) { console.log('loadForeignKeyDisplayValuesFromTheServer() - ' + foreignType, foreignKeyEventHandlerName, idArray, '\n' + lookupFilterField + ' = ' + lookupFilterValue); }

    if (foreignKeyEventHandlerName !== null) {

      if (this._eventServerService.getKeyFieldType(foreignKeyEventHandlerName) !== 'String') {

        // format the comma-separated string of ids to lookup in the EventHandler...
        const parms: any = {};

        // if we only want specific IDs, put them on the idList...
        if (idArray && (idArray.length > 0)) {
          let idList = '';
          for (let i = 0; i < idArray.length; i++) {
            idList += idArray[i];
            if ((i + 1) < idArray.length) {
              idList += ',';
            }
          }
          // console.log('loadForeignKeyDisplayValuesFromTheServer()\nforeignType: ' + foreignType + ', idArray: ' + JSON.stringify(idArray));
          parms.idList = idList;
        }

        // Now, if we're filtering the list, add the filter parms
        if (lookupFilterParms) {
          for (const [name, value] of Object.entries(lookupFilterParms)) {
            parms[name] = value;
          }
        }

        // const debug = (foreignKeyEventHandlerName === 'TaskTemplateGroups');

        // if (debug) { console.log('loadForeignKeyDisplayValuesFromTheServer() - calling ' + foreignKeyEventHandlerName + '.lookup()', parms, new Error().stack); }

        return this._eventServerService.fireEvent(foreignKeyEventHandlerName, 'lookup', parms).pipe(
          catchError(
            (error: any) => {
              console.log(error);

              // let errMsg = null;
              // if (error.error instanceof ErrorEvent) {
              //   // Get client-side error
              //   errMsg = error.error.message;
              // } else {
              //   // Get server-side error
              //   errMsg = `Error Code: ${error.status}\nMessage: ${error.message}`;
              // }
              // return errMsg;

              return error;
            }
          )
          , map (
            (event: WEvent) => {

              // if (debug) { console.log(event); }

              if (event.status === 'OK') {
                // Good Event...

                // walk the returned resources (which are are specific to the lookup method... and NOT defined in the API!)
                if (event.hasOwnProperty('resources')) {
                  // if (debug) console.log('loadForeignKeyDisplayValuesFromTheServer() - \nevent.resource.length: ' + event.resource[0].length);
                  for (const res of event.resources) {

                    const fkeyValue = res.key.value;
                    const fkeyLabel = res.displayValue.value;

                    // console.log('loadForeignKeyDisplayValuesFromTheServer()', foreignType, fkeyValue, fkeyLabel);

                    // save it for write/edit mode...
                    if (fkeyLabel) {
                      this.setForeignKeyDisplayValue(
                        foreignType,
                        fkeyValue,
                        fkeyLabel
                      );
                    }
                  }

                  // return "null" to tell the subscriber that everything worked OK...
                  return null;

                } else {
                  errorMessage = 'No selections available for resourceType: ' + foreignType;
                }
              } else {
                // Bad Event...
                console.log('loadForeignKeyDisplayValuesFromTheServer() - Bad Event', event);
                errorMessage = 'Error Message: ' + event.message;
              }
            }
          )
        );

      } else {
        errorMessage = 'Application Design Error: We never figured we would have to lookup things with a key of type String: ' + foreignType;
      }

    } else {
      // this used to happen when regular users click on the Users tab, then we changed the userID field to a Long...
      errorMessage = 'Application Design Error: User does not have access to resources of type: ' + foreignType;
    }
    console.log('loadForeignKeyDisplayValuesFromTheServer()', errorMessage);
    return of<string>(errorMessage);
  }

  convertToFakeForeignKeyField(resource: WResource, fakeFKeyType: string, fakeFKeyFieldName: string, fakeFKeyFilterParms?: any): void {
    const fakeFKeyEH = this._eventServerService.getEventHandlerForResourceType(fakeFKeyType);
    const tempFakeResource = this._eventServerService.newResource(fakeFKeyEH);

    this.loadForeignKeyDisplayValuesFromTheServer(fakeFKeyType, null, fakeFKeyFilterParms).subscribe(
      () => {
        // console.log('callback from loadForeignKeyDisplayValuesFromTheServer()', fakeFKeyType, this.getForeignKeyDisplayValues(fakeFKeyType));

        // console.log('callback from loadForeignKeyDisplayValuesFromTheServer(): ' + msg);

        // Now go populate the field... if we have a value...

        const fakeFKeyValue = resource[fakeFKeyFieldName].value;

        if (fakeFKeyValue && (fakeFKeyValue !== '')) {

          tempFakeResource.keyField.value = fakeFKeyValue;

          const parms = tempFakeResource.keyField.asParm;

          this._eventServerService.loadResourceFromServer(fakeFKeyEH, parms).subscribe(
            (fakeFKeyResource: WResource) => {
              // console.log('callback from loadResourceFromServer()', fakeFKeyResource);

              if (!fakeFKeyResource) {
                resource[fakeFKeyFieldName].displayValue = '(no longer exists)';
              } else {

                const fld = new WForeignKey(fakeFKeyFieldName, fakeFKeyResource.getField(fakeFKeyFieldName).value, fakeFKeyEH);

                fld.foreignKey = true;
                fld.foreignType = fakeFKeyType;
                fld.displayValue = this.getForeignKeyDisplayValue(fakeFKeyType, fakeFKeyValue);
                fld.foreignKeyFilterParms = fakeFKeyFilterParms;
                fld.displayComponent = 'dropdown';

                // console.log('fld:', fld);
                resource[fakeFKeyFieldName] = fld;
              }
            }
          );

        } else {

          const fld = new WForeignKey(fakeFKeyFieldName, null, fakeFKeyEH);

          fld.foreignKey = true;
          fld.foreignType = fakeFKeyType;
          fld.foreignKeyFilterParms = fakeFKeyFilterParms;
          fld.displayComponent = 'dropdown';

          // console.log('fld:', fld);
          resource[fakeFKeyFieldName] = fld;
        }
      }
    );

  }

  convertToSelectFieldFromOtherResourceType(resource: WResource, localFieldName: string, otherFieldName: string, otherResourceType: string, otherEHFilterParms?: any): void {
    const localValue = resource[localFieldName].value;

    const otherEH = this._eventServerService.getEventHandlerForResourceType(otherResourceType);

    if (localValue && (localValue !== '')) {

      const parms: any = {};
      parms[otherFieldName] = localValue;

      this._eventServerService.loadResourceFromServer(otherEH, parms, false).subscribe(
        (otherResource: WResource) => {
          if (!otherResource) {
            resource[localFieldName].value = null;
            resource[localFieldName].displayValue = '(no longer exists)';
          }
          this._buildSelectField(resource, localFieldName, localValue, otherFieldName, otherEH, otherEHFilterParms);
        }
      );
    } else {
      this._buildSelectField(resource, localFieldName, localValue, otherFieldName, otherEH, otherEHFilterParms);
    }
  }

  private _buildSelectField(resource: WResource, localFieldName: string, localValue: any, otherFieldName: string, otherEH: string, otherEHFilterParms?: any): void {
    otherEHFilterParms = typeof otherEHFilterParms !== 'undefined' ? otherEHFilterParms : {};

    const parms2: any = otherEHFilterParms;
    parms2.pageSize = -1;
    parms2.sortBy = otherFieldName;
    parms2.sortDirection = 1;

    // console.log('UISvc._buildSelectField()', resource, localFieldName, otherFieldName, otherEHFilterParms, otherEH, parms2);

    this._eventServerService.fireEvent(otherEH, 'list', parms2).subscribe(
      (event: WEvent) => {
        // console.log('UISvc._buildSelectField()', event);
        if (event.status === 'OK') {
          const fld = new WSelect(localFieldName, (localValue ? localValue : ''));
          let selectValues = '';

          for (const r of event.resources) {
            selectValues += '|' + r.getField(otherFieldName).value;
          }

          fld.select = selectValues;
          // fld.size = 5; // this does NOT WORK to control how many items the select has...

          // console.log('UISvc._buildSelectField()', 'fld:', fld);

          resource[localFieldName] = fld;

        } else {
          this._modalDialogService.showAlert(event.message, 'Unable to load values for ' + localFieldName);
        }
      }
    );
  }

  focusOnField(parentElementRef: ElementRef, f: WField): void {
    const selector = '[name="' + f.name + '"]';  // input? select? both?
    const element: HTMLElement = parentElementRef.nativeElement.querySelector(selector) as HTMLElement;
    // console.log('UI.Svc.focusOnField()', selector, element);
    if (element) {
      window.setTimeout(() => element.focus(), 0);
    }
  }

  selectFieldContents(parentElementRef: ElementRef, f: WField): void {
    const selector = '[name="' + f.name + '"]';  // input? select? both?
    const element: HTMLInputElement = parentElementRef.nativeElement.querySelector(selector) as HTMLInputElement;
    // console.log('UI.Svc.focusOnField()', selector, element);
    if (element) {
      window.setTimeout(() => element.select(), 0);
    }
  }

  getOffset(el: HTMLElement ): { top: number, left: number} {
      let _x = 0;
      let _y = 0;
      do {
          _x += el.offsetLeft || 0;
          _y += el.offsetTop  || 0;
          el = el.offsetParent as HTMLElement;
      } while (el);

      return { top: _y, left: _x };
  }

  ///////////////////////////////////////
  // ElementRef based image size methods
  ///////////////////////////////////////

  getImageSize(parentElementRef: ElementRef, selector: string, debug?: boolean): {height: number, width: number} {
    const imageElement: HTMLImageElement = parentElementRef.nativeElement.querySelector(selector) as HTMLImageElement;
    let temp: {height: number, width: number} = null;
    if (imageElement) {
      const height = imageElement.naturalHeight;
      const width = imageElement.naturalWidth;
      temp = {height, width};
    }

    if (debug) { console.log(parentElementRef, selector, imageElement, temp); }

    return temp;

  }

  imageIsABanner(parentElementRef: ElementRef, selector: string, debug?: boolean): boolean {
    let temp = false;
    const response = this.getImageSize(parentElementRef, selector, debug);
    if (response && (response.width >= Globals.minBannerImageWidth) && (response.width / response.height > Globals.maxLogoImageRatio)) {
      temp = true;
    }
    return temp;
  }

  imageHasGoodRatioForLogo(parentElementRef: ElementRef, selector: string, debug?: boolean): boolean {
    let temp = false;
    const response = this.getImageSize(parentElementRef, selector, debug);
    if (response && (response.width / response.height <= Globals.maxLogoImageRatio)) {
      temp = true;
    }
    return temp;
  }

  getImageStatistics(parentElementRef: ElementRef, selector: string, debug?: boolean): {width: number, height: number, aspectRatio: string, goodLogoRatio: boolean, isBanner: boolean} {
    let temp: {width: number, height: number, aspectRatio: string, goodLogoRatio: boolean, isBanner: boolean} = null;
    const response = this.getImageSize(parentElementRef, selector, debug);
    if (response) {
      const aspectRatio = (response.width / response.height);

      temp = {width: null, height: null, aspectRatio: null, goodLogoRatio: null, isBanner: null};

      temp.width = response.width;
      temp.height = response.height;
      temp.aspectRatio = aspectRatio.toFixed(2) + ':1';
      temp.goodLogoRatio = (aspectRatio <= Globals.maxLogoImageRatio);
      temp.isBanner = (response.width >= Globals.minBannerImageWidth) && (aspectRatio > Globals.maxLogoImageRatio);
    }

    if (debug) { console.log(parentElementRef, selector, temp); }

    return temp;

  }

}
