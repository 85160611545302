<div *ngIf="!isLoggedIn">
	<ngb-carousel #carousel interval="5000">
		<ng-template ngbSlide *ngFor="let img of images; let idx = index">
			<div class="carousel-caption">
				<div class="right staticCarouselText">
					<div class="row">
						<div class="col-sm-4">
						</div>
						<div class="col-sm-8 black">
					    <div *ngIf="screenType !== 'fullscreen'"><br/><br/></div>
							<div>Welcome to {{ domain }}!</div> 
							<div class="small" *ngIf="domain === 'wackadoo.org'">(a division of <a href="https://www.wackadoo.info">wackadoo.info</a>)</div>
							<h1 class="marginTop2 green">
								<!-- REMEMBER: ApplicationMetaData.list() will auto-add the appID, but "search()" does not... -->
								<span *wackadooResourceBlock="let r of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{appID:1,type:'tagline',sequence:1};">
									{{ r.value.value }}
								</span>
							</h1>
						</div>
					</div>
					<div class="row" *ngIf="screenType === 'fullscreen'">
						<div class="col-sm-6">
						</div>
						<div class="black col-sm-6">
							<div *ngIf="domain === 'wackadoo.info'">
								Impactful, highly-targeted business web apps that are simple, 
								straight-forward and easy to use.
							</div>
							<div *ngIf="domain === 'wackadoo.org'">
								Subsized (sometimes donated...) web apps that assist ministries, churches, 
								volunteers and non-profit organizations serving people in need.
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="faded-image image{{idx}}">
				<!-- 
					We don't put the url in here. Instead, we set background-image on the  
					imageX class (using code...) in order to implement a CSS fade effect...
				-->
				<img src=" " alt="" />
			</div>
		</ng-template>
	</ngb-carousel>
	<div class="" *ngIf="screenType !== 'fullscreen'">
		<div class="">
			<div class="well right">
				<div *ngIf="domain === 'wackadoo.info'">
					Impactful, highly-targeted business web apps that are simple, 
					straight-forward and easy to use.
				</div>
				<div *ngIf="domain === 'wackadoo.org'">
					Subsized (sometimes donated...) web apps that assist ministries, churches, 
					volunteers and non-profit organizations serving people in need.
				</div>
			</div>
		</div>
	</div>
	<div class="marginTopHalfEm marginLeftWholeEm marginRightWholeEm marginBottonWholeEm"
			wackadooMaximizeContentHeight
			maxHeightThingBelowSelector=".wackadoo-footer"
			[maxHeightFudgeFactor]="-16"
	>
		<p class="bold center green" [ngClass]="{ 'extraLarge' : (screenType === 'fullscreen') , 'large bold' : (screenType !== 'fullscreen') }">
			<span *wackadooResourceBlock="let r of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{appID:1,type:'tagline',sequence:2};">
				{{ r.value.value }}
			</span>
		</p>
		<div class="row">
			<div class="col-sm-6 col-md-4 center" 
				*wackadooResourceBlock="let r of []; eventHandler:'Applications'; action:'list'; parms:{status:'released'};" 
				[ngClass]="{hidden: r.appName.value === 'AccountManager'}"
			>
				<div class="d-flex justify-content-around">
					<div class="d-flex align-items-center">
						<div>
							<a class="nowrap"  [ngClass]="{ 'extraLarge' : (screenType === 'fullscreen') , 'large bold' : (screenType !== 'fullscreen') }" href="./{{r.appName.value}}" >
								<span class="img-circle img-responsive img-center">
									<!-- we hack out the prefix for now... -->
									<span class="fas fa-{{ r.appIcon.value }}"></span>
								</span>
								<span>
									{{ r.appName.value }}
								</span>
								<span class="italic" *ngIf="r.status.value !== 'released'">({{ r.status.value }})</span>
							</a>
						</div>
						<div class="marginLeftWholeEm" *ngIf="user && user.isPublicOnly">
							<wackadoo-sign-up-button [appName]="r.appName.value"></wackadoo-sign-up-button>
						</div>
					</div>
				</div>
				<p class="" [ngClass]="{ 'large' : (screenType === 'fullscreen') }">{{ r.appDescription.value }}</p>
			</div>
			<div class="col-sm-6 col-md-4 center" 
				*wackadooResourceBlock="let r of []; eventHandler:'Applications'; action:'list'; parms:{status:'beta'};"
			>
				<div class="d-flex justify-content-around">
					<div class="d-flex align-items-center">
						<div>
							<a class="nowrap"  [ngClass]="{ 'extraLarge' : (screenType === 'fullscreen') , 'large bold' : (screenType !== 'fullscreen') }" href="./{{r.appName.value}}" >
								<span class="img-circle img-responsive img-center">
									<!-- we hack out the prefix for now... -->
									<span class="fas fa-{{ r.appIcon.value }}"></span>
								</span>
								<span>
									{{ r.appName.value }}
								</span>
								<span class="italic" *ngIf="r.status.value !== 'released'">({{ r.status.value }})</span>
							</a>
						</div>
						<div class="marginLeftWholeEm" *ngIf="user && user.isPublicOnly">
							<wackadoo-sign-up-button [appName]="r.appName.value"></wackadoo-sign-up-button>
						</div>
					</div>
				</div>
				<p class="" [ngClass]="{ 'large' : (screenType === 'fullscreen') }">{{ r.appDescription.value }}</p>
			</div>
			<div class="wackadoo-line marginTopHalfEm marginBottomWholeEm"></div>
			<div class="row">
					<div class="col-sm-1">
					</div>
					<div class="col-sm-5 center">
						<a class="nowrap"  [ngClass]="{ 'extraLarge' : (screenType === 'fullscreen') , 'large bold' : (screenType !== 'fullscreen') }" routerLink="about-custom-dev">
							<span class="img-circle img-responsive img-center">
								<span class="fas fa-question-circle"></span>
							</span>
							<span>
								We Build Custom Web Apps!
							</span>
						</a>
						<p class="" [ngClass]="{ 'large' : (screenType === 'fullscreen') }">
							If you have ever thought 
							<span class="italics">"I just need a web site that would do this one thing for me,
							but nobody has something that fits my specific situation"</span>, 
							we can probably do that for you.
							<br/>
							<a routerLink="contact-us">Just give us a call</a>.
						</p>
					</div>
					<div class="col-sm-5 center">
						<a class="nowrap"  [ngClass]="{ 'extraLarge' : (screenType === 'fullscreen') , 'large bold' : (screenType !== 'fullscreen') }" routerLink="contact-us">
							<span class="img-circle img-responsive img-center">
								<span class="fas fa-tools" aria-hidden="true"></span>
							</span>
							<span>
								Technology Consulting
							</span>
						</a>
						<p class="" [ngClass]="{ 'large' : (screenType === 'fullscreen') }">
							A lot of times non-profit organizations just don't have access to someone who they can call with a quick 
							question - or even something that requires a little more work.
							From networking to email to spreadsheets to programming to... well, whatever you just hurled an expletive at -  we'd love to help you out.
							<br/>
							<a routerLink="contact-us">Just drop us a line</a>.
						</p>
					</div>
					<div class="col-sm-1">
					</div>
			</div>
		</div>
	</div>
</div>
<div class="marginWholeEm" *ngIf="user && isLoggedIn">
	<div class="jumbotron shadow marginWholeEm paddingTopWholeEm paddingBottomWholeEm">
		<h2>Welcome {{ user.fullName }}</h2>
		<h3> from {{ user.accountName }}!</h3>
		<br/>
		<p>
			userName: {{ user.userName }}
			<br/>
			login time: {{ user.lastAccess }})
		</p>
	</div>
	<!--
	    we check for user because sometimes we don't have one fast enough on the public page, 
    	like when we immediately jump to the "firstPage" due to business rule processing...
	-->
	<div *ngIf="accountManagerIsRootApplication">
		<div class="marginTop6 card large" *ngIf="user.isAdminOrBelow">
			<div class="card-header">
				Your Applications
			</div>
			<div class="card-body scrollable"
				wackadooMaximizeContentHeight
				maxHeightThingBelowSelector=".wackadoo-footer"
				[maxHeightFudgeFactor]="-16"
			>
				<span *wackadooResourceBlock="let r1 of []; eventHandler:'RegisteredApplications'; action:'list'; parms:{sortBy:'appName', sortDirection: 1};">
					<span class="" *ngIf="(r1.appName) && (r1.appName.value !== 'AccountManager')">
						<span *ngIf="r1.expiration && isNotExpired(r1.expiration.value)">
							<span *wackadooResourceBlock="let r2 of []; eventHandler:'Applications'; action:'list'; parms:{appID: r1.appID.value};">
								<a href="./{{ r2.appName.value }}">
									<!-- we hack out the prefix for now... -->
									<span class="fas fa-{{ r2.appIcon.value }}"></span>
									{{ r2.appName.value }}</a> 
								<p [ngClass]="{small:(screenType === 'phone')}">
									{{ r2.appDescription.value }}
								</p>
							</span>
						</span>
					</span>
				</span>
			</div>
		</div>
	</div>
</div>
