import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChartsModule } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgxCaptureModule } from 'ngx-capture';

// core wackadoo-client app stuff
import { ClientCoreRoutingModule } from './client-core-routing.module';

// core data components
import { FieldComponent } from './data/field/field.component';
import { StringComponent } from './data/field/string/string.component';
import { UrlComponent } from './data/field/url/url.component';
import { EmailComponent } from './data/field/email/email.component';
import { PhoneComponent } from './data/field/phone/phone.component';
import { ColorComponent } from './data/field/color/color.component';
import { HtmlComponent } from './data/field/html/html.component';
import { EncryptedComponent } from './data/field/encrypted/encrypted.component';
import { PasswordComponent } from './data/field/password/password.component';
import { UniqueComponent } from './data/field/unique/unique.component';
import { BooleanComponent } from './data/field/boolean/boolean.component';
import { DateComponent } from './data/field/date/date.component';
import { WholeNumberComponent } from './data/field/whole-number/whole-number.component';
import { RealNumberComponent } from './data/field/real-number/real-number.component';
import { SelectComponent } from './data/field/select/select.component';
import { ForeignKeyComponent } from './data/field/foreign-key/foreign-key.component';
import { BinaryComponent } from './data/field/binary/binary.component';
import { BinaryImageComponent } from './data/field/binary/binary-image/binary-image.component';
import { BinaryTextComponent } from './data/field/binary/binary-text/binary-text.component';
import { BinaryHtmlComponent } from './data/field/binary/binary-html/binary-html.component';
import { BinaryAudioComponent } from './data/field/binary/binary-audio/binary-audio.component';
import { BinaryVideoComponent } from './data/field/binary/binary-video/binary-video.component';
import { ResourceComponent } from './data/resource/resource.component';

// core UI directives and re-usable sub-components for page components
import { FileUploadDirective } from './ui/file-upload.directive';
import { FileReadDirective } from './ui/file-read.directive';
import { FileDropDirective } from './ui/file-drop.directive';
import { FileDragOverDirective } from './ui//file-dragover.directive';
import { StopPropagationDirective } from './ui/stop-propagation.directive';
import { ResourceDropDirective } from './ui/resource-drop.directive';
import { ResourceDragOverDirective } from './ui//resource-dragover.directive';
import { ResourceDragStartDirective } from './ui/resource-dragstart.directive';
import { PageLoadDirective } from './ui/page-load.directive';
import { SelectResourceDirective } from './ui/select-resource.directive';
import { ResourceBlockDirective } from './ui/resource-block.directive';
import { ResourcePlaceholderDirective } from './ui/resource-placeholder.directive';
import { ChartBlockComponent } from './ui/modal-dialogs/chart-modal/chart-block.component';
import { MultiSelectListComponent } from './ui/multi-select-list/multi-select-list.component';
import { MultiSelectListManyToManyComponent } from './ui/multi-select-list/multi-select-list-many-to-many.component';
import { SingleSelectListComponent } from './ui/single-select-list/single-select-list.component';
import { TypeAheadComponent } from './ui/type-ahead/type-ahead.component';
import { StyleDirective } from './ui/style.directive';
import { NameAndLogoComponent } from './ui/name-and-logo/name-and-logo.component';

// pages
import { HomeAppComponent } from './pages/home-app/home-app.component';
import { PoweredByComponent } from './pages/powered-by/powered-by.component';
import { CarouselComponent } from './pages/home-app/carousel/carousel.component';
import { AppSpecificPublicContentPlaceholderDirective } from './pages/home-app/public-content-placeholder.directive';
import { PageComponent } from './pages/page/page.component';
import { EventPageComponent } from './pages/event-page/event-page.component';
import { RepositoryPageComponent } from './pages/repository-page/repository-page.component';

// placeholders for calculating dynamic height on resource repository and report pages...
import { GridContentPlaceholderDirective } from './pages/repository-page/grid-content-placeholder.directive';
import { GridButtonBarPlaceholderDirective } from './pages/repository-page/grid-button-bar-placeholder.directive';
import { DetailContentPlaceholderDirective } from './pages/repository-page/detail-content-placeholder.directive';
import { DetailButtonBarPlaceholderDirective } from './pages/repository-page/detail-button-bar-placeholder.directive';

// Grid components
import { GridComponent } from './ui/grid/grid.component';
import { GridControlBarComponent } from './ui/grid/grid-control-bar/grid-control-bar.component';
import { PageControlComponent } from './ui/grid/grid-control-bar/page-control/page-control.component';
import { SortControlComponent } from './ui/grid/grid-control-bar/sort-control/sort-control.component';
import { SearchBoxComponent } from './ui/grid/grid-control-bar/search-box/search-box.component';

// Grid page components
import { GridButtonBarComponent } from './pages/repository-page/grid-button-bar/grid-button-bar.component';

// Detail page components
import { DetailContentComponent } from './pages/repository-page/detail-content/detail-content.component';
import { DetailButtonBarComponent } from './pages/repository-page/detail-button-bar/detail-button-bar.component';
import { RowPipe } from './ui/row-pipe';
import { ColumnPipe } from './ui/column-pipe';

// common pages (must be in all apps, same across all apps)
import { CheckForResRepComponent } from './pages/check-for-res-rep/check-for-res-rep.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

// help banner-link pages (required pages that must be in each app)
import { HelpComponent } from './pages/help/help.component';
import { HelpGettingStartedComponent } from './pages/help/help-getting-started/help-getting-started.component';
import { HelpUserRolesComponent } from './pages/help/help-user-roles/help-user-roles.component';
import { HelpFaqComponent } from './pages/help/help-faq/help-faq.component';
import { HelpVideoLibraryComponent } from './pages/help/help-video-library/help-video-library.component';

// custom directives...
import { PagePlaceholderDirective } from './ui/main/page-placeholder.directive';
import { MenuPlaceholderDirective } from './ui/main/menu-placeholder.directive';
import { AppHeaderPlaceholderDirective } from './ui/main/app-header-placeholder.directive';
import { MenuCloserDirective } from './ui/menu-closer.directive';

// main UX display components
import { BannerComponent } from './ui/banner/banner.component';
import { ClockComponent } from './ui/banner/clock/clock.component';
import { MainComponent } from './ui/main/main.component';
import { MenuComponent } from './ui/main/menu/menu.component';
import { AccountMessageComponent } from './ui/main/account-message/account-message.component';
import { CurrentSelectionComponent } from './ui/main/current-selection/current-selection.component';
import { FooterComponent } from './ui/footer/footer.component';

// modal components, etc. (dynamically generated...)
import { PleaseWaitComponent } from './ui/modal-dialogs/please-wait/please-wait.component';
import { AlertComponent } from './ui/modal-dialogs/alert/alert.component';
import { ChartModalComponent } from './ui/modal-dialogs/chart-modal/chart-modal.component';
import { ConfirmComponent } from './ui/modal-dialogs/confirm/confirm.component';
import { PromptComponent } from './ui/modal-dialogs/prompt/prompt.component';
import { SearchComponent } from './ui/modal-dialogs/search/search.component';
import { SystemMessageComponent } from './ui/modal-dialogs/system-message/system-message.component';
import { ErrorModalComponent } from './ui/modal-dialogs/error-modal/error-modal.component';
import { ResourceModalComponent } from './ui/modal-dialogs/resource-modal/resource-modal.component';
import { ResourceModalPlaceholderDirective } from './ui/modal-dialogs/resource-modal/resource-modal-placeholder.directive';
import { ResourceSelectModalComponent } from './ui/modal-dialogs/resource-select-modal/resource-select-modal.component';
import { SelectValueComponent } from './ui/modal-dialogs/resource-modal/select-value.component';
import { EMailerComponent } from './ui/modal-dialogs/e-mailer/e-mailer.component';
import { SafeHtmlPipe } from './ui/safe-html.pipe';

// other banner pages
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { LoginComponent } from './pages/login/login.component';

// about banner-link pages
import { AboutComponent } from './pages/about/about.component';
import { AboutCustomDevComponent } from './pages/about/about-custom-dev/about-custom-dev.component';
import { AboutNameComponent } from './pages/about/about-name/about-name.component';
import { AboutMissionComponent } from './pages/about/about-mission/about-mission.component';
import { AboutTechnologyComponent } from './pages/about/about-technology/about-technology.component';
import { AboutHistoryComponent } from './pages/about/about-history/about-history.component';
// value banner-link pages
import { ValuesComponent } from './pages/values/values.component';
import { ValueDirectnessComponent } from './pages/values/value-directness/value-directness.component';
import { ValueSimplicityComponent } from './pages/values/value-simplicity/value-simplicity.component';
import { ValueFlexibilityComponent } from './pages/values/value-flexibility/value-flexibility.component';
import { ValueSubstanceOverStyleComponent } from './pages/values/value-substance-over-style/value-substance-over-style.component';
import { ValueTransparencyComponent } from './pages/values/value-transparency/value-transparency.component';
// policy banner-link pages
import { PoliciesComponent } from './pages/policies/policies.component';
import { PolicyTermsAndConditionsComponent } from './pages/policies/policy-terms-and-conditions/policy-terms-and-conditions.component';
import { PolicyDataSecurityComponent } from './pages/policies/policy-data-security/policy-data-security.component';
import { PolicyPricingComponent } from './pages/policies/policy-pricing/policy-pricing.component';
import { PolicyBillingComponent } from './pages/policies/policy-billing/policy-billing.component';
import { PolicyRefundsComponent } from './pages/policies/policy-refunds/policy-refunds.component';
import { PolicyExpiredDelinquentAcctsComponent } from './pages/policies/policy-exp-delinq-accts/policy-exp-delinq-accts.component';
import { PolicyEmailUsageComponent } from './pages/policies/policy-email-usage/policy-email-usage.component';
import { PolicyConfidentialityComponent } from './pages/policies/policy-confidentiality/policy-confidentiality.component';
import { PolicyCookiesComponent } from './pages/policies/policy-cookies/policy-cookies.component';
import { PolicyDataRetentionComponent } from './pages/policies/policy-data-retention/policy-data-retention.component';
import { PolicyDataPortabilityComponent } from './pages/policies/policy-data-portability/policy-data-portability.component';
import { PolicySupportComponent } from './pages/policies/policy-support/policy-support.component';
import { PolicyMaintenanceComponent } from './pages/policies/policy-maintenance/policy-maintenance.component';
import { PolicyServiceLevelAgreementComponent } from './pages/policies/policy-sla/policy-sla.component';

// account-level admin pages, used across multiple web-apps... (Really? All of them?)
import { BackupServiceComponent } from './pages/backup-service/backup-service.component';
import { SearchServiceComponent } from './pages/search-service/search-service.component';
import { SearchEngineUpdaterComponent } from './pages/search-engine-updater/search-engine-updater.component';
import { SecurityServicesComponent } from './pages/security-services/security-services.component';

// Common functional pages and/or components, used across multiple web-apps...
import { BackupRestoreComponent } from './pages/backup-restore/backup-restore.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { BulkEMailerComponent } from './pages/bulk-e-mailer/bulk-e-mailer.component';
import { EMailErrorsComponent } from './pages/e-mail-errors/e-mail-errors.component';
import { ImportTemplatesComponent } from './pages/import-templates/import-templates.component';
import { ImportTemplateFieldsComponent } from './pages/import-template-fields/import-template-fields.component';
import { ImportTemplatesDetailComponent } from './pages/import-templates/import-templates-detail/import-templates-detail.component';
import { ImportTemplateReportTextAreaDirective } from './pages/import-templates/import-templates-detail/import-format-report-text-area.directive';
import { ImportTemplateFieldsDetailComponent } from './pages/import-template-fields/import-template-fields-detail/import-template-fields-detail.component';
import { ReportTemplatesComponent } from './pages/report-templates/report-templates.component';
import { ReportTemplateDetailContentComponent } from './pages/report-templates/report-template-detail-content/report-template-detail-content.component';
import { ReportTemplateDetailButtonBarComponent } from './pages/report-templates/report-template-detail-button-bar/report-template-detail-button-bar.component';
import { ReportTemplateGridButtonBarComponent } from './pages/report-templates/report-template-grid-button-bar/report-template-grid-button-bar.component';
import { MaximizeContentHeightDirective } from './ui/maximize-content-height.directive';
import { EMailTemplatesComponent } from './pages/e-mail-templates/e-mail-templates.component';
import { EMailTemplateGridButtonBarComponent } from './pages/e-mail-templates/e-mail-template-grid-button-bar/e-mail-template-grid-button-bar.component';
import { EMailTemplateDetailContentComponent } from './pages/e-mail-templates/e-mail-template-detail-content/e-mail-template-detail-content.component';
import { EMailTemplateDetailButtonBarComponent } from './pages/e-mail-templates/e-mail-template-detail-button-bar/e-mail-template-detail-button-bar.component';
import { CalendarPageComponent } from './pages/calendar-page/calendar-page.component';
import { MonthComponent } from './pages/calendar-page/month/month.component';
import { WeekComponent } from './pages/calendar-page/week/week.component';
import { DayComponent } from './pages/calendar-page/day/day.component';
import { CalendarHeaderComponent } from './pages/calendar-page/calendar-header/calendar-header.component';
import { SignUpButtonComponent } from './ui/sign-up-button/sign-up-button.component';
import { DuplicateMergerComponent } from './ui/duplicate-merger/duplicate-merger.component';
import { BroadcastComponent } from './pages/broadcast/broadcast.component';
import { SelectColorModalComponent } from './ui/select-color-modal/select-color-modal.component';
import { FileDetailsComponent } from './ui/file-details/file-details.component';
import { SmtpServersComponent } from './pages/smtp-servers/smtp-servers.component';
import { SmtpServerDetailContentComponent } from './pages/smtp-servers/smtp-server-detail-content/smtp-server-detail-content.component';
import { SelectSmtpServerPasswordComponent } from './ui/modal-dialogs/e-mailer/select-smtp-server-password/select-smtp-server-password.component';
import { SimplePasswordComponent } from './data/field/simple-password/simple-password.component';
import { DonateComponent } from './pages/donate/donate.component';
import { StripePaymentComponent } from './ui/stripe-payment/stripe-payment.component';
import { ThankYouComponent } from './pages/donate/thank-you.component';
import { PriceListComponent } from './pages/price-list/price-list.component';
import { PriceListItemsComponent } from './pages/donate/price-list-items/price-list-items.component';
import { PriceListDetailContentComponent } from './pages/price-list/price-list-detail-content/price-list-detail-content.component';
import { PriceListItemDetailComponent } from './pages/donate/price-list-item-detail/price-list-item-detail.component';
import { PriceListWithInitializationComponent } from './pages/price-list-with-initialization/price-list-with-initialization.component';
import { PriceListItemDetailAutoUpdateComponent } from './pages/price-list-with-initialization/price-list-item-detail-auto-update/price-list-item-detail-auto-update.component';
import { DonorInfoComponent } from './pages/donate/donor-info/donor-info.component';
import { ShoppingCartItemDetailComponent } from './pages/donate/shopping-cart-item-detail/shopping-cart-item-detail.component';
import { ShoppingCartLogComponent } from './pages/shopping-cart-log/shopping-cart-log.component';
import { ShoppingCartLogDetailComponent } from './pages/shopping-cart-log/shopping-cart-log-detail/shopping-cart-log-detail.component';
import { GeoMapDirective } from './ui/geo-map.directive';
import { FormFillerTemplatesComponent } from './pages/form-filler-templates/form-filler-templates.component';
import { FormFillerFieldComponent } from './pages/form-filler-templates/form-filler-field/form-filler-field.component';
import { FormFillerComponent } from './ui/form-filler/form-filler.component';
import { FormFillersComponent } from './pages/form-fillers/form-fillers.component';
import { FormFillerFieldsComponent } from './pages/form-filler-fields/form-filler-fields.component';
import { PriceListAddModalComponent } from './pages/price-list-with-initialization/price-list-add-modal/price-list-add-modal.component';
import { PriceListPackageSelectModalComponent } from './pages/price-list-with-initialization/price-list-package-select-modal/price-list-package-select-modal.component';
import { AutoFocusDirective } from './ui/auto-focus.directive';
import { ImageLoadedDirective } from './ui/image-loaded.directive';

@NgModule({
  declarations: [

    // core data components
    FieldComponent,
    StringComponent,
    UrlComponent,
    EmailComponent,
    PhoneComponent,
    ColorComponent,
    HtmlComponent,
    EncryptedComponent,
    PasswordComponent,
    UniqueComponent,
    BooleanComponent,
    WholeNumberComponent,
    RealNumberComponent,
    SelectComponent,
    ForeignKeyComponent,
    DateComponent,
    BinaryComponent,
    BinaryTextComponent,
    BinaryHtmlComponent,
    BinaryImageComponent,
    BinaryAudioComponent,
    BinaryVideoComponent,
    ResourceComponent,

    // core UI directives and re-usable sub-components for page components
    FileUploadDirective,
    FileReadDirective,
    FileDropDirective,
    FileDragOverDirective,
    StopPropagationDirective,
    ResourceDropDirective,
    ResourceDragOverDirective,
    ResourceDragStartDirective,
    PageLoadDirective,
    SelectResourceDirective,
    ResourceBlockDirective,
    ResourcePlaceholderDirective,
    ChartBlockComponent,
    MultiSelectListComponent,
    MultiSelectListManyToManyComponent,
    SingleSelectListComponent,
    TypeAheadComponent,
    StyleDirective,
    NameAndLogoComponent,

    // pages
    HomeAppComponent,
    PoweredByComponent,
    CarouselComponent,
    AppSpecificPublicContentPlaceholderDirective,
    PageComponent,
    EventPageComponent,
    RepositoryPageComponent,

    // resource repository page directives
    GridContentPlaceholderDirective,
    GridButtonBarPlaceholderDirective,
    DetailContentPlaceholderDirective,
    DetailButtonBarPlaceholderDirective,

    // Grid components
    GridComponent,
    GridControlBarComponent,
    PageControlComponent,
    SortControlComponent,
    SearchBoxComponent,

    // Grid page components
    GridButtonBarComponent,

    // Detail page components
    DetailContentComponent,
    DetailButtonBarComponent,
    RowPipe,
    ColumnPipe,

    // custom directives
    MenuPlaceholderDirective,
    PagePlaceholderDirective,
    AppHeaderPlaceholderDirective,
    MenuCloserDirective,

    // main UX display components
    BannerComponent,
    ClockComponent,
    MenuComponent,
    MainComponent,
    AccountMessageComponent,
    CurrentSelectionComponent,
    FooterComponent,

    // modal components, etc. (dynamically generated...)
    PleaseWaitComponent,
    AlertComponent,
    ChartModalComponent,
    ConfirmComponent,
    PromptComponent,
    SearchComponent,
    SystemMessageComponent,
    ErrorModalComponent,
    ResourceModalComponent,
    ResourceModalPlaceholderDirective,
    ResourceSelectModalComponent,
    SelectValueComponent,
    EMailerComponent,
    SafeHtmlPipe,

    // pages
    ContactUsComponent,
    LoginComponent,

    // common pages
    CheckForResRepComponent,
    PageNotFoundComponent,

    // about banner-link pages
    AboutComponent,
    AboutCustomDevComponent,
    AboutNameComponent,
    AboutMissionComponent,
    AboutTechnologyComponent,
    AboutHistoryComponent,
    // value banner-link pages
    ValuesComponent,
    ValueDirectnessComponent,
    ValueSimplicityComponent,
    ValueFlexibilityComponent,
    ValueSubstanceOverStyleComponent,
    ValueTransparencyComponent,
    // policy banner-link pages
    PoliciesComponent,
    PolicyTermsAndConditionsComponent,
    PolicyDataSecurityComponent,
    PolicyPricingComponent,
    PolicyBillingComponent,
    PolicyRefundsComponent,
    PolicyExpiredDelinquentAcctsComponent,
    PolicyEmailUsageComponent,
    PolicyConfidentialityComponent,
    PolicyCookiesComponent,
    PolicyDataRetentionComponent,
    PolicyDataPortabilityComponent,
    PolicySupportComponent,
    PolicyMaintenanceComponent,
    PolicyServiceLevelAgreementComponent,
    // help banner page components
    HelpComponent,
    HelpGettingStartedComponent,
    HelpUserRolesComponent,
    HelpFaqComponent,
    HelpVideoLibraryComponent,

    // Common account admin pages...
    BackupServiceComponent,
    SearchServiceComponent,
    SearchEngineUpdaterComponent,
    SecurityServicesComponent,

    // Common functional pages and/or components...
    BackupRestoreComponent,
    ReportsComponent,
    BulkEMailerComponent,
    EMailErrorsComponent,
    ImportTemplatesComponent,
    ImportTemplateFieldsComponent,
    ImportTemplatesDetailComponent,
    ImportTemplateReportTextAreaDirective,
    ImportTemplateFieldsDetailComponent,
    ReportTemplatesComponent,
    ReportTemplateDetailContentComponent,
    ReportTemplateDetailButtonBarComponent,
    ReportTemplateGridButtonBarComponent,
    MaximizeContentHeightDirective,
    EMailTemplatesComponent,
    EMailTemplateGridButtonBarComponent,
    EMailTemplateDetailContentComponent,
    EMailTemplateDetailButtonBarComponent,
    CalendarPageComponent,
    MonthComponent,
    WeekComponent,
    DayComponent,
    CalendarHeaderComponent,
    SignUpButtonComponent,
    DuplicateMergerComponent,
    BroadcastComponent,
    SelectColorModalComponent,
    FileDetailsComponent,
    SmtpServersComponent,
    SmtpServerDetailContentComponent,
    SelectSmtpServerPasswordComponent,
    SimplePasswordComponent,
    DonateComponent,
    StripePaymentComponent,
    ThankYouComponent,
    PriceListComponent,
    PriceListItemsComponent,
    PriceListDetailContentComponent,
    PriceListItemDetailComponent,
    PriceListWithInitializationComponent,
    PriceListItemDetailAutoUpdateComponent,
    DonorInfoComponent,
    ShoppingCartItemDetailComponent,
    ShoppingCartLogComponent,
    ShoppingCartLogDetailComponent,
    GeoMapDirective,
    FormFillerTemplatesComponent,
    FormFillerComponent,
    FormFillerFieldComponent,
    FormFillersComponent,
    FormFillerFieldsComponent,
    PriceListAddModalComponent,
    PriceListPackageSelectModalComponent,
    AutoFocusDirective,
    ImageLoadedDirective,

  ],
  imports: [
    // Angular stuff...
    CommonModule,
    HttpClientModule,
    FormsModule,

    // 3rd party stuff everybody uses...
    NgbModule,
    FontAwesomeModule,
    ChartsModule,
    EditorModule,

    // core client stuff
    ClientCoreRoutingModule,

  ],
  exports: [
    // Angular stuff everybody uses...
    FormsModule,

    // 3rd party stuff everybody uses...
    NgbModule,
    FontAwesomeModule,
    ChartsModule,
    EditorModule,
    NgxCaptureModule,

    // routing for common pages for all apps...
    ClientCoreRoutingModule,

    // core data components
    FieldComponent,
    StringComponent,
    UrlComponent,
    EmailComponent,
    PhoneComponent,
    ColorComponent,
    HtmlComponent,
    EncryptedComponent,
    PasswordComponent,
    UniqueComponent,
    BooleanComponent,
    WholeNumberComponent,
    RealNumberComponent,
    SelectComponent,
    ForeignKeyComponent,
    DateComponent,
    BinaryComponent,
    BinaryTextComponent,
    BinaryHtmlComponent,
    BinaryImageComponent,
    BinaryAudioComponent,
    BinaryVideoComponent,
    ResourceComponent,

    // core UI directives and re-usable sub-components for page components
    FileUploadDirective,
    FileReadDirective,
    FileDropDirective,
    FileDragOverDirective,
    StopPropagationDirective,
    ResourceDropDirective,
    ResourceDragOverDirective,
    ResourceDragStartDirective,
    PageLoadDirective,
    SelectResourceDirective,
    ResourceBlockDirective,
    ResourcePlaceholderDirective,
    ChartBlockComponent,
    MultiSelectListComponent,
    MultiSelectListManyToManyComponent,
    SingleSelectListComponent,
    TypeAheadComponent,
    StyleDirective,
    NameAndLogoComponent,

    // pages
    HomeAppComponent,
    PoweredByComponent,
    CarouselComponent,
    AppSpecificPublicContentPlaceholderDirective,
    PageComponent,
    EventPageComponent,
    RepositoryPageComponent,

    // resource repository page directives
    GridContentPlaceholderDirective,
    GridButtonBarPlaceholderDirective,
    DetailContentPlaceholderDirective,
    DetailButtonBarPlaceholderDirective,

    // Grid components
    GridComponent,
    GridControlBarComponent,
    PageControlComponent,
    SortControlComponent,
    SearchBoxComponent,

    // Grid page components
    GridButtonBarComponent,

    // Detail page components
    DetailContentComponent,
    DetailButtonBarComponent,
    RowPipe,
    ColumnPipe,

    // custom directives
    MenuPlaceholderDirective,
    PagePlaceholderDirective,
    AppHeaderPlaceholderDirective,
    MenuCloserDirective,

    // main UX display components
    BannerComponent,
    ClockComponent,
    MenuComponent,
    MainComponent,
    AccountMessageComponent,
    CurrentSelectionComponent,
    FooterComponent,

    // modal components, etc. (dynamically generated...)
    PleaseWaitComponent,
    AlertComponent,
    ChartModalComponent,
    ConfirmComponent,
    PromptComponent,
    SearchComponent,
    SystemMessageComponent,
    ErrorModalComponent,
    ResourceModalComponent,
    ResourceModalPlaceholderDirective,
    ResourceSelectModalComponent,
    SelectValueComponent,
    EMailerComponent,
    SafeHtmlPipe,

    // pages
    ContactUsComponent,
    LoginComponent,

    // common pages
    CheckForResRepComponent,
    PageNotFoundComponent,

    // about banner-link pages
    AboutComponent,
    AboutCustomDevComponent,
    AboutNameComponent,
    AboutMissionComponent,
    AboutTechnologyComponent,
    AboutHistoryComponent,
    // value banner-link pages
    ValuesComponent,
    ValueDirectnessComponent,
    ValueSimplicityComponent,
    ValueFlexibilityComponent,
    ValueSubstanceOverStyleComponent,
    ValueTransparencyComponent,
    // policy banner-link pages
    PoliciesComponent,
    PolicyTermsAndConditionsComponent,
    PolicyDataSecurityComponent,
    PolicyPricingComponent,
    PolicyBillingComponent,
    PolicyRefundsComponent,
    PolicyExpiredDelinquentAcctsComponent,
    PolicyEmailUsageComponent,
    PolicyConfidentialityComponent,
    PolicyCookiesComponent,
    PolicyDataRetentionComponent,
    PolicyDataPortabilityComponent,
    PolicySupportComponent,
    PolicyMaintenanceComponent,
    PolicyServiceLevelAgreementComponent,
    // help banner page components
    HelpComponent,
    HelpGettingStartedComponent,
    HelpUserRolesComponent,
    HelpFaqComponent,
    HelpVideoLibraryComponent,

    // Common account admin pages...
    BackupServiceComponent,
    SearchServiceComponent,
    SearchEngineUpdaterComponent,
    SecurityServicesComponent,

    // Common functional pages and/or components...
    BackupRestoreComponent,
    ReportsComponent,
    BulkEMailerComponent,
    EMailErrorsComponent,
    ImportTemplatesComponent,
    ImportTemplateFieldsComponent,
    ImportTemplatesDetailComponent,
    ImportTemplateReportTextAreaDirective,
    ImportTemplateFieldsDetailComponent,
    ReportTemplatesComponent,
    ReportTemplateDetailContentComponent,
    ReportTemplateDetailButtonBarComponent,
    ReportTemplateGridButtonBarComponent,
    MaximizeContentHeightDirective,
    EMailTemplatesComponent,
    EMailTemplateGridButtonBarComponent,
    EMailTemplateDetailContentComponent,
    EMailTemplateDetailButtonBarComponent,
    CalendarPageComponent,
    SignUpButtonComponent,
    DuplicateMergerComponent,
    BroadcastComponent,
    SelectColorModalComponent,
    FileDetailsComponent,
    SmtpServersComponent,
    SmtpServerDetailContentComponent,
    SelectSmtpServerPasswordComponent,
    DonateComponent,
    StripePaymentComponent,
    ThankYouComponent,
    PriceListComponent,
    PriceListItemsComponent,
    PriceListDetailContentComponent,
    PriceListItemDetailComponent,
    PriceListWithInitializationComponent,
    PriceListItemDetailAutoUpdateComponent,
    DonorInfoComponent,
    ShoppingCartItemDetailComponent,
    ShoppingCartLogComponent,
    ShoppingCartLogDetailComponent,
    GeoMapDirective,
    FormFillerTemplatesComponent,
    FormFillerComponent,
    FormFillersComponent,
    FormFillerFieldsComponent,
    PriceListAddModalComponent,
    AutoFocusDirective,
    ImageLoadedDirective,
  ],
  providers: [
    RowPipe,
    ColumnPipe,
    SafeHtmlPipe,
  ],
  bootstrap: [],
  entryComponents: [
    GridButtonBarComponent,
    DetailContentComponent,
    DetailButtonBarComponent,
    ImportTemplatesDetailComponent,
    ImportTemplateFieldsDetailComponent,
    ReportTemplateDetailContentComponent,
    ReportTemplateDetailButtonBarComponent,
    ReportTemplateGridButtonBarComponent,
    EMailTemplateGridButtonBarComponent,
    EMailTemplateDetailContentComponent,
    EMailTemplateDetailButtonBarComponent,
    SelectColorModalComponent,
    SmtpServerDetailContentComponent,
    SelectSmtpServerPasswordComponent,
    PriceListDetailContentComponent,
    PriceListItemDetailComponent,
    PriceListItemDetailAutoUpdateComponent,
    ShoppingCartItemDetailComponent,
    ShoppingCartLogDetailComponent,
    FormFillerComponent,
    PriceListAddModalComponent,
    PriceListPackageSelectModalComponent,
  ]
})
export class ClientCoreModule { }
